import React, { useState } from "react";
import styled from "styled-components";

const Dropdown = ({ options, value, className, handleSelect }) => {

  const getDefaultLabel = () => {
    let label = options[0].label;
    options.map((item) => {
      if (item.key === value) {
        label = item.label;
      }
    });

    return label
  }

  const [open, toggleOpen] = useState(false);
  const [selected, setSelected] = useState(value ? getDefaultLabel() : options[0].label);

  const handleClick = (option) => {
    handleSelect(option.key);
    toggleOpen(false);
  };

  return (
    <DropdownContainer className={className}>
      <button
        className={"select-button" + (open ? " select-open" : "")}
        onClick={() => toggleOpen(!open)}
      >
        <a href="#" className="T6">
          {selected}
        </a>
        <i className="arrow-icon"></i>
      </button>
      {open ? (
        <ul className="options" onBlur={() => toggleOpen(false)}>
          {options.map((option) => {
            return (
              <li
                className={
                  "option" + (selected === option.label ? " selected" : "")
                }
                key={option.key}
                onClick={() => {
                  setSelected(option.label);
                  handleClick(option);
                }}
              >
                <a href="#" className="T6">
                  {option.label}
                </a>
              </li>
            );
          })}
        </ul>
      ) : null}
    </DropdownContainer>
  );
};

export default Dropdown;

const DropdownContainer = styled.div`
  position: relative;
  margin-top: 20px;
  /* border: 1px solid #c6cad4; */
  border-radius: 10px;
  height: 100%;
  z-index: 1;

  a {
    text-decoration: none;
    color: var(--C4);
  }
  .select-button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 16px;
    background: var(--C8);
    border: 1px solid #c6cad4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.select-open {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      .arrow-icon {
        transition: all 0.4s ease;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      & + ul {
        transform: translateY(0px);
      }
    }

    .arrow-icon {
      border: solid var(--C2);
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-left: 10px;

      transition: all 0.4s ease;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .options {
    position: absolute;
    border: 1px solid #c6cad4;
    border-radius: 0 0 10px 10px;
    z-index: 1;
    padding: 0;
    margin: 0;
    background: var(--C8);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    border-radius: 0 0 10px 10px;

    transform: translateY(-10px);
    transition: all 0.4s ease;

    .option {
      z-index: 1;
      height: 100%;
      width: 100%;
      padding: 16px;

      &:hover {
        background-color: var(--C2);
        cursor: pointer;
        a {
          color: var(--C8);
        }
      }

      /* &.selected {
        background-color: var(--C2);
        a {
          color: var(--C8);
        }
      } */
    }
  }
`;
