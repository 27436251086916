import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ServiceContext } from "../contexts/ServiceContext";
import { ResourcesContext } from "../contexts/ResourcesContext";

import ServiceList from "../components/ServiceList";
import ServiceDetails from "../components/ServiceDetails";
import SearchServices from "../components/SearchServices";
import firebase from "../firebase";

const SupportListPage = () => {
  const { content, lang } = useContext(ResourcesContext);
  const { selectedService, filteredServices, searchParam } = useContext(ServiceContext);
  const [headerSize, setHeaderSize] = useState("large");

  if (content === undefined) {
    return null;
  }
  const _content = content.supportlist;

  const getTopHeight = () => {
    const SearchService = document.getElementById("SearchServices");
      if (SearchService) {
        return SearchService.offsetHeight;
      }
  }

  const getSearchNotFoundText = () => {
    firebase.analytics().logEvent('search_not_found', {search_result: searchParam });
    let _text = _content ? `${_content.search_not_found_text} "${searchParam}"` : `Could not find any services matching "${searchParam}"`;

    if (lang === "sinhala") {
      _text = _content ? `"${searchParam}" ${_content.search_not_found_text}` : `Could not find any services matching "${searchParam}"`;
    } else if (lang === "tamil") {
      _text = _content ? `"${searchParam}" ${_content.search_not_found_text}` : `Could not find any services matching "${searchParam}"`;
    }

    return _text;
  }

  return (
    <SupportListContainer topHeight={getTopHeight()}>
      <SearchServices
        headerSize={headerSize}
        className={
          "search-services" +
          (selectedService.hasOwnProperty("id") ? " hidden" : "nothidden")
        }
      />
      {filteredServices === null ? <h2 className="not-found-text">{getSearchNotFoundText()}</h2> : null}
      <div className="services">
        <ServiceList handleListScroll={(size) => setHeaderSize(size)} />
        <ServiceDetails />
      </div>
    </SupportListContainer>
  );
};

export default SupportListPage;

const SupportListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--C10);
  min-height: calc(100vh - 60px);
  position: relative;
  top: 64px;
  
  .not-found-text {
    display: grid;
    grid-column: 1/-1;
    margin: 30px 0px;
    position: relative;
    padding: 0px 20px; 
    top: ${(props) => `${props.topHeight}px`};
  }

  .services {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    top: -64px;
  }

  .mobile-nav-back {
    position: fixed;
    width: 100%;
    z-index:1;
  }


  @media screen and (min-width: 768px) {
    /* Tablet & Larger*/
   top: 0px;
   .services {
    position: relative;
    top: 0px;
   
  }
  }

  /* Smaller than laptops */
  @media screen and (max-width: 960px) {
    .not-found-text {
      color: var(--C5);
    }
    .hidden {
      display: none;
    }
    .service-list {
      grid-column: 1/-1;
    }
    .service-details {
      display: none;
      &.active {
        grid-column: 1/-1;
        grid-row: 2;
        display: block;
        min-height: calc(100vh - 64px);
        height: 100%;
        position: relative;
        top: 64px;
      }
    }

    .mobile-nav-back {
      display: none;
      &.active {
        grid-column: 1/-1;
        grid-row: 1;
        display: flex;
      }
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
    .not-found-text {
      padding: 0px 20px;
      top: 0px;
      color: var(--C5);
    }
    .services {
      grid-template-columns: repeat(8, 1fr);
      padding: 0px 20px;

      .service-list {
        grid-column: 1/9;
      }
    }
  }

  /* Laptop */
  @media screen and (min-width: 960px) {
    .not-found-text {
      /* grid-template-columns: repeat(12, 1fr); */
      padding: 0px 70px;
    }
    .services {
      grid-template-columns: repeat(12, 1fr);
      padding: 0px 70px;

      .service-list {
        grid-column: 1/7;
        grid-row: 1;
      }
      .service-details {
        display: block;
        grid-column: 7/13;
        grid-row: 1;
      }
    }
  }
`;
