import React, { useState, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../contexts/AuthContext";

const Register = () => {
  const { register } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword && password !== "") {
      register(email, password);
      setErrorMessage("");
    } else {
      setErrorMessage("Password & Confirm Password must match");
    }
  };

  return (
    <Container>
      <div className="form-container">
        <h2>Sign Up</h2>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <form className="login" onSubmit={handleSubmit}>
          <input
            type="text"
            name={email}
            required
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name={password}
            required
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            name={confirmPassword}
            required
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button className="T5">Sign Up</button>
        </form>
      </div>
    </Container>
  );
};

export default Register;

const Container = styled.div`
  background-color: var(--C12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);

  @media screen and (max-width: 768px) {
    /* Tablet */
    background-color: var(--C8);
  }

  .form-container {
    background-color: var(--C8);
    border-radius: 15px;
    box-shadow: 2px 4px 20px rgba(73, 90, 149, 0.05);
    width: 450px;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      /* Tablet */
      box-shadow: none;
      border-radius: 0;
    }

    .error-message {
      color: red;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;

      input {
        margin-top: 20px;
        border: 1px solid #c6cad4;
        padding: 15px;
        border-radius: 10px;
        width: 100%;
      }

      button {
        margin-top: 40px;
        background-color: var(--C8);
        color: #495a95;
        border: 2px solid #495a95;
        border-radius: 8px;
        padding: 15px 0px;
        width: 180px;
      }
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: 15px;
  font-family: "NotoSans SemiBold", sans-serif;
  color: red;
`;
