import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../assets/icons/icon_close.svg";
import Button from "./Button";

const CustomModal = ({ show, loading, title, closeModal, children, footerLinkText, footerLinkClick, footerButtonText, footerButtonClick, height, width, top, padding }) => {

  console.log('loading >>', loading)
  return (
    <ModalContainer show={show} onHide={closeModal}
      height={height}
      width={width}
      padding={padding}
      top={top}
      centered>
      <Modal.Header>
        <Modal.Title>
          <button className="close-button" onClick={closeModal}>
            <CloseIcon />
          </button>
          <p className="T3 title">{title}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable>
        {children}
      </Modal.Body>
      {(footerLinkText && footerLinkClick || footerButtonText && footerButtonClick) && !loading ?
        <Modal.Footer>
          {footerLinkText && footerLinkClick &&
            <Button
              type="link"
              className="T5 back-link"
              text={footerLinkText}
              onClick={footerLinkClick}
            />
          }
          {footerButtonText && footerButtonClick &&
            <Button
              text={footerButtonText}
              onClick={footerButtonClick}
            />
          }
        </Modal.Footer>
        : null}
    </ModalContainer>
  );
};

export default CustomModal;

const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width:  ${(props) => props.width ? props.width : '416px'};
    .modal-content {
      border: none;
      border-radius: 8px;

      .modal-header {
        display: block;
        border-bottom: none;
        padding: 0px 24px;

        .modal-title {
          color: var(--C5);
          display: flex;
          align-items: center;
          padding: 0px;
          height: 64px;
        }
    
        .close-button {
          background-color: var(--C8);
          height: 40px;
          width: 45px;
          transform: translate(-8px);
          margin: 0px;
          padding: 0px;
          border-radius: 50%;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          &:hover {
            background-color: var(--C10);
          }
        }
    
        .title {
          text-align: center;
          justify-content: center;
          width: 100%;
          color: var(--C5);
          margin-left: -20px;
          transform: translate(-24px);
        }
      }

      .modal-body {
        padding: ${(props) => props.padding ? props.padding : '24px'};
        background-color: var(--C8);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      
      .modal-footer {
        padding: 24px;
        background-color: var(--C8);
        margin-bottom: 0px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @media screen and (max-width: 369px) {
          justify-content: center;
          flex-direction: column-reverse;
          .button {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-dialog {
      margin: 0px;
      max-width: 100%;

      .modal-content {
        height: 100vh;
        border-radius: 0px;

        .modal-header {
          border-bottom: 0.5px solid var(--C12);
          padding: 0 20px;
          .title {
            transform: translate(-12px);
          }
        }
        .modal-body {
          padding: 24px 20px;
          background-color: var(--C8);
      }
      
        .modal-footer {
          padding: 24px 20px;
        }
      }
    }
  }
  
`