import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import firebase from "../firebase";

import { ServiceContext } from "../contexts/ServiceContext";
import { ResourcesContext } from "../contexts/ResourcesContext";
import TextGroup from "../components/TextGroup";

const AddService = () => {
  const history = useHistory();
  const location = useLocation();
  const paramId = new URLSearchParams(location.search).get("id");

  const { categories } = useContext(ResourcesContext);
  const { services } = useContext(ServiceContext);

  let service = null;
  if (paramId) {
    services.map((item) => {
      if (item.id === paramId) {
        service = item;
      }
    });
  }
  console.log("Add Service - service", service);

  const [errorMessage, setErrorMessage] = useState("");
  const [logo, setLogo] = useState(null);
  const [progress, setProgress] = useState(0);

  const [serivceName, setServiceName] = useState(
    service ? service.serviceName : ""
  ); // service name in all 3 languages separeted by commas (this field will be used to do the search)

  const [logourl, setLogoURL] = useState(
    service && service.common ? service.common.logourl : ""
  );
  const [category, setCategory] = useState(
    service && service.common ? service.common.category : ""
  );
  const [cost, setCost] = useState(
    service && service.common ? service.common.cost : ""
  );
  const [tags, setTags] = useState(
    service && service.common ? service.common.tags : []
  );
  const [phone, setPhone] = useState(
    service && service.common ? service.common.phone : []
  );
  const [email, setEmail] = useState(
    service && service.common ? service.common.email : []
  );
  const [addressLoc, setAddressLoc] = useState(
    service && service.common ? service.common.addressLoc : ""
  );
  const [website, setWebsite] = useState(
    service && service.common ? service.common.website : ""
  );
  const [facebook, setFacebook] = useState(
    service && service.common ? service.common.socials.facebook : ""
  );
  const [instagram, setInstagram] = useState(
    service && service.common ? service.common.socials.instagram : ""
  );
  const [twitter, setTwitter] = useState(
    service && service.common ? service.common.socials.twitter : ""
  );

  // english
  const [englishName, setEnglishName] = useState(
    service && service.english ? service.english.name : ""
  );
  const [englishDesc, setEnglishDesc] = useState(
    service && service.english ? service.english.description : ""
  );
  const [englishAddressLine1, setEnglishAddressLine1] = useState(
    service && service.english ? service.english.address.addressLine1 : ""
  );
  const [englishAddressLine2, setEnglishAddressLine2] = useState(
    service && service.english ? service.english.address.addressLine2 : ""
  );
  const [englishCity, setEnglishCity] = useState(
    service && service.english ? service.english.address.city : ""
  );

  // sinhala
  const [sinhalaName, setSinhalaName] = useState(
    service && service.sinhala ? service.sinhala.name : ""
  );
  const [sinhalaDesc, setSinhalaDesc] = useState(
    service && service.sinhala ? service.sinhala.description : ""
  );
  const [sinhalaAddressLine1, setSinhalaAddressLine1] = useState(
    service && service.sinhala ? service.sinhala.address.addressLine1 : ""
  );
  const [sinhalaAddressLine2, setSinhalaAddressLine2] = useState(
    service && service.sinhala ? service.sinhala.address.addressLine2 : ""
  );
  const [sinhalaCity, setSinhalaCity] = useState(
    service && service.sinhala ? service.sinhala.address.city : ""
  );

  // tamil
  const [tamilName, setTamilName] = useState(
    service && service.tamil ? service.tamil.name : ""
  );
  const [tamilDesc, setTamilDesc] = useState(
    service && service.tamil ? service.tamil.description : ""
  );
  const [tamilAddressLine1, setTamilAddressLine1] = useState(
    service && service.tamil ? service.tamil.address.addressLine1 : ""
  );
  const [tamilAddressLine2, setTamilAddressLine2] = useState(
    service && service.tamil ? service.tamil.address.addressLine2 : ""
  );
  const [tamilCity, setTamilCity] = useState(
    service && service.tamil ? service.tamil.address.city : ""
  );

  const uploadLogo = (e) => {
    console.log("upload logo >> ", logo);
    const storageRef = firebase
      .storage()
      .ref(`images/service_logos/${logo.name}`)
      .put(logo);
    storageRef.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref("images/service_logos")
          .child(logo.name)
          .getDownloadURL()
          .then((url) => {
            console.log("logo url >?>>> ", url);
            setLogoURL(url);
          });
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO - Form validation
    // TODO - format & remove empty array items

    const id = localStorage.getItem("FBIdToken");
    const payload = {
      name: `${englishName} - ${sinhalaName} - ${tamilName}`,
      common: {
        logourl,
        category,
        cost,
        tags,
        phone,
        email,
        addressLoc,
        website,
        socials: {
          facebook,
          instagram,
          twitter,
        },
      },
      english: {
        name: englishName,
        description: englishDesc,
        address: {
          addressLine1: englishAddressLine1,
          addressLine2: englishAddressLine2,
          city: englishCity,
        },
      },
      sinhala: {
        name: sinhalaName,
        description: sinhalaDesc,
        address: {
          addressLine1: sinhalaAddressLine1,
          addressLine2: sinhalaAddressLine2,
          city: sinhalaCity,
        },
      },
      tamil: {
        name: tamilName,
        description: tamilDesc,
        address: {
          addressLine1: tamilAddressLine1,
          addressLine2: tamilAddressLine2,
          city: tamilCity,
        },
      },
    };

    console.log("payload", payload);
    if (paramId) {
      // edit service
      payload.id = paramId;
      axios
        .put(`/services/edit-service/${paramId}`, payload, {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        })
        .then((res) => {
          console.log(
            `${englishName} - ${sinhalaName} - ${tamilName} service updated`
          );
          setErrorMessage("");
          history.push("/support-list");
        })
        .catch((err) => {
          console.log("error", err);
          window.scrollTo(0, 0);
          setErrorMessage(err.message);
        });
    } else {
      // add service
      axios
        .post("/services/add-service", payload, {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        })
        .then((res) => {
          console.log(
            `${englishName} - ${sinhalaName} - ${tamilName} service added`
          );
          setErrorMessage("");
          history.push("/support-list");
        })
        .catch((err) => {
          console.log("error", err);
          window.scrollTo(0, 0);
          setErrorMessage(err.message);
        });
    }
  };

  const addToState = (values, type) => {
    const formattedValues = values.filter((item) => {
      return item != "";
    });
    switch (type) {
      case "phone":
        setPhone(formattedValues);
        break;
      case "email":
        setEmail(formattedValues);
        break;
      default:
        console.log("Type does not match");
    }
  };

  const handleTag = (checked, type) => {
    let _tags = tags;
    if (checked) {
      _tags.push(type);
    } else {
      _tags = _tags.filter((tag) => {
        return tag !== type;
      });
    }
    setTags(_tags);
  };
  const categoryOptions = [
    { label: "Service Category", key: "select_default_service_category" },
    ...categories,
  ];

  return (
    <Container>
      {paramId ? <h4>Update Service</h4> : <h4>New Service</h4>}
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <form className="add-service" id="AddServiceForm" onSubmit={handleSubmit}>
        <div className="samana-col-1">
          <div className="logo-upload">
            {logourl !== "" && (
              <p className="uploaded-file">
                <span className="bold">Uploaded Image:</span>{" "}
                {logourl.substring(logourl.lastIndexOf("/") + 1)}
              </p>
            )}
            <div className="upload-options">
              <input
                type="file"
                name={logo}
                placeholder="Upload Logo"
                onChange={(e) => setLogo(e.target.files[0])}
              />
              <button className="T5" onClick={uploadLogo}>
                Upload
              </button>
            </div>
            <div className="upload-progress">
              {progress > 0 && progress < 100 && (
                <progress value={progress} max="100" />
              )}
              {progress === 100 && (
                <p className="upload-complete">Logo Upload Completed</p>
              )}
            </div>
          </div>

          <select name="category" onChange={(e) => setCategory(e.target.value)}>
            {categoryOptions.map((category) =>
              category.key === "select_default_service_category" &&
              service === null ? (
                <option
                  key={"default"}
                  className="select-placeholder"
                  value=""
                  disabled
                  selected
                >
                  {category.label}
                </option>
              ) : (
                <option
                  key={category.key}
                  value={category.key}
                  selected={
                    service && service.common.category === category.key
                      ? "selected"
                      : null
                  }
                >
                  {category.label}
                </option>
              )
            )}
          </select>

          <div className="accessibility-tags">
            <p className="T10">Accessibility Tags</p>
            <div className="radio-buttons">
              <div className="radio-button">
                <input
                  type="radio"
                  id="free"
                  name="price"
                  value="free"
                  defaultChecked={service && service.common.cost === "free"}
                  onClick={(e) => setCost(e.target.value)}
                />
                <label className="T6" htmlFor="free">
                  Free
                </label>
              </div>
              <div className="radio-button">
                <input
                  type="radio"
                  id="cost"
                  name="price"
                  value="cost"
                  defaultChecked={service && service.common.cost === "cost"}
                  onClick={(e) => setCost(e.target.value)}
                />
                <label className="T6" htmlFor="cost">
                  Costs Involved
                </label>
              </div>
            </div>
            <div className="checkbox-group">
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="lgbtqia"
                  name="lgbtqia"
                  defaultChecked={tags.includes("lgbtqia")}
                  onClick={(e) => handleTag(e.target.checked, "lgbtqia")}
                />
                <label className="T6" htmlFor="lgbtqia">
                  LGBTQIA+ Friendly
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="government_service"
                  name="government_service"
                  defaultChecked={tags.includes("government_service")}
                  onClick={(e) =>
                    handleTag(e.target.checked, "government_service")
                  }
                />
                <label className="T6" htmlFor="government_service">
                  Government Service
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="counselling_available"
                  name="counselling_available"
                  defaultChecked={tags.includes("counselling_available")}
                  onClick={(e) =>
                    handleTag(e.target.checked, "counselling_available")
                  }
                />
                <label className="T6" htmlFor="counselling_available">
                  Counselling Available
                </label>
              </div>
            </div>
          </div>

          <TextGroup
            label="Phone Number"
            type="phone"
            defaultDisplay={1}
            values={phone}
            addToState={addToState}
          />
          <TextGroup
            label="Email"
            type="email"
            defaultDisplay={1}
            values={email}
            addToState={addToState}
          />
          <input
            type="text"
            name={addressLoc}
            placeholder="Address Map Location"
            value={addressLoc}
            onChange={(e) => setAddressLoc(e.target.value)}
          />
          <input
            type="text"
            name={website}
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />

          <input
            type="text"
            name={facebook}
            placeholder="Facebook"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />

          <input
            type="text"
            name={instagram}
            placeholder="Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />

          <input
            type="text"
            name={twitter}
            placeholder="Twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
        </div>

        <div className="samana-col-2">
          <div className="service-lang-heading service-english">
            <h5>Service - English</h5>
            <input
              type="text"
              name={englishName}
              required
              placeholder="Service Name in English"
              value={englishName}
              onChange={(e) => {
                if (e.target.value[0]) {
                  setEnglishName(e.target.value);
                }
              }}
            />
            <textarea
              className="message"
              rows="4"
              cols="50"
              name={englishDesc}
              form="AddServiceForm"
              placeholder="Description in English"
              value={englishDesc}
              required="required"
              onChange={(e) => setEnglishDesc(e.target.value)}
            />
            <div className="address">
              <input
                type="text"
                name={englishAddressLine1}
                placeholder="Address Line 1 in English"
                value={englishAddressLine1}
                onChange={(e) => setEnglishAddressLine1(e.target.value)}
              />
              <input
                type="text"
                name={englishAddressLine2}
                placeholder="Address Line 2 in English"
                value={englishAddressLine2}
                onChange={(e) => setEnglishAddressLine2(e.target.value)}
              />
              <input
                type="text"
                name={englishCity}
                placeholder="City in English"
                value={englishCity}
                onChange={(e) => setEnglishCity(e.target.value)}
              />
            </div>
          </div>

          <div className="service-lang-heading service-sinhala">
            <h5>Service - Sinhala</h5>
            <input
              type="text"
              name={sinhalaName}
              required
              placeholder="Service Name in Sinhala"
              value={sinhalaName}
              onChange={(e) => {
                if (e.target.value[0]) {
                  setSinhalaName(e.target.value);
                }
              }}
            />
            <textarea
              className="message"
              rows="4"
              cols="50"
              name={sinhalaDesc}
              form="AddServiceForm"
              placeholder="Description in Sinhala"
              value={sinhalaDesc}
              required="required"
              onChange={(e) => setSinhalaDesc(e.target.value)}
            />
            <div className="address">
              <input
                type="text"
                name={sinhalaAddressLine1}
                placeholder="Address Line 1 in Sinhala"
                value={sinhalaAddressLine1}
                onChange={(e) => setSinhalaAddressLine1(e.target.value)}
              />
              <input
                type="text"
                name={sinhalaAddressLine2}
                placeholder="Address Line 2 in Sinhala"
                value={sinhalaAddressLine2}
                onChange={(e) => setSinhalaAddressLine2(e.target.value)}
              />
              <input
                type="text"
                name={sinhalaCity}
                placeholder="City in Sinhala"
                value={sinhalaCity}
                onChange={(e) => setSinhalaCity(e.target.value)}
              />
            </div>
          </div>

          <div className="service-lang-heading service-tamil">
            <h5>Service - Tamil</h5>
            <input
              type="text"
              name={tamilName}
              required
              placeholder="Service Name in Tamil"
              value={tamilName}
              onChange={(e) => {
                if (e.target.value[0]) {
                  setTamilName(e.target.value);
                }
              }}
            />
            <textarea
              className="message"
              rows="4"
              cols="50"
              name={tamilDesc}
              form="AddServiceForm"
              placeholder="Description in Tamil"
              value={tamilDesc}
              required="required"
              onChange={(e) => setTamilDesc(e.target.value)}
            />
            <div className="address">
              <input
                type="text"
                name={tamilAddressLine1}
                placeholder="Address Line 1 in Tamil"
                value={tamilAddressLine1}
                onChange={(e) => setTamilAddressLine1(e.target.value)}
              />
              <input
                type="text"
                name={tamilAddressLine2}
                placeholder="Address Line 2 in Tamil"
                value={tamilAddressLine2}
                onChange={(e) => setTamilAddressLine2(e.target.value)}
              />
              <input
                type="text"
                name={tamilCity}
                placeholder="City in Tamil"
                value={tamilCity}
                onChange={(e) => setTamilCity(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* <div className="service-sinhala">
          <h5>Service - Sinhala</h5>
          <input
            type="text"
            name={sinhala.name}
            required
            placeholder="Service Name - Sinhala"
            value={sinhala.name}
            onChange={(e) => {
              if (e.target.value[0]) {
                setServiceSinhala.name(e.target.value);
              }
            }}
          />
        </div> */}
        <button className="T5 submit-button">
          {paramId ? "Update Service" : "Add Service"}
        </button>
      </form>
    </Container>
  );
};

export default AddService;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--C8);
  margin: 0 auto;

  .add-service {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 20px;
    max-width: 1140px;

    .samana-col-1 {
      grid-column: 1/-1;

      .logo-upload {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        /* width: 600px; */

        .uploaded-file {
          font-size: 16px;
          font-family: "NotoSans Regular", sans-serif;
          margin-bottom: 10px;

          .bold {
            font-weight: 700;
            margin-right: 5px;
          }
        }

        .upload-options {
          display: flex;
          input {
            margin-top: 0px;
            margin-right: 20px;
          }

          button {
            margin-top: 0px;
          }
        }

        .upload-progress {
          .upload-complete {
            font-size: 15px;
            color: var(--C7);
            text-align: center;
            margin-top: 10px;
          }

          progress {
            margin-top: 10px;
            height: 30px;
            width: 100%;
          }
        }
      }

      .accessibility-tags {
        border: 1px solid #b8bdc8;
        border-radius: 8px;
        padding: 10px;
        margin-top: 20px;

        .checkbox-group {
          display: flex;
          flex-direction: column;

          .checkbox {
            margin-bottom: 10px;

            input {
              width: 20px;
              margin: 0px;
              margin-right: 5px;
            }

            input,
            label {
              cursor: pointer;
            }
          }
        }

        .radio-buttons {
          display: flex;
          margin: 10px 0px;
          .radio-button {
            margin-right: 10px;
            input {
              width: 20px;
              margin: 0px;
              margin-right: 5px;
            }
            input,
            label {
              cursor: pointer;
            }
          }
        }
      }
    }

    .samana-col-2 {
      grid-column: 1/-1;

      .service-lang-heading {
        margin-top: 20px;
        border: 1px solid var(--C1);
        padding: 20px;
        border-radius: 5px;

        h5 {
          color: var(--C2);
        }
      }
    }
    .submit-button {
      grid-column: 1/-1;
      justify-self: center;
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
    .add-service {
      grid-template-columns: repeat(8, 1fr);

      .samana-col-1 {
        grid-column: 1/4;
      }
      .samana-col-2 {
        grid-column: 5/9;
      }
    }
  }

  /* Laptop */
  @media screen and (min-width: 960px) {
    .add-service {
      grid-template-columns: repeat(12, 1fr);
      padding: 0;
      max-width: 1140px;

      .samana-col-1 {
        grid-column: 1/6;
      }
      .samana-col-2 {
        grid-column: 7/13;
      }
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: 15px;
  font-family: "NotoSans SemiBold", sans-serif;
  color: red;
`;
