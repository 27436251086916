import React, { useContext } from "react";
import styled from "styled-components";
import { ResourcesContext } from "../contexts/ResourcesContext";

const CategoryTag = ({ category }) => {
  const { categories } = useContext(ResourcesContext);
  let _category = null;
  categories.map((item) => {
    if (item.key === category) {
      _category = item;
    }
  });

  if (_category === null) {
    return null;
  }

  return (
    <Tag className={`tag-${_category.colorCode}`}>
      <p className="T7">{_category.label}</p>
    </Tag>
  );
};

export default CategoryTag;

const Tag = styled.div`
  border-radius: 8px;
  display: inline-block;

  p {
    padding: 4px 12px;
    line-height: 20px;
    color: var(--C4);
  }
`;
