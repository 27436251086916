import React from "react";
import Lottie from 'react-lottie';
import loading from '../assets/lottie/loading_spinner.json'

const Spinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div>
      <Lottie 
	    options={defaultOptions}
        height={300}
        width={300} />
    </div>
  )
}

export default Spinner;