import React, { createContext, useState, useEffect } from "react";
import firebase from "../firebase";

export const ResourcesContext = createContext();

const ResourcesContextProvider = (props) => {
  const [lang, setLang] = useState("english");
  const [resources, setResources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState({});
  const [content, setContent] = useState({});

  const changeLanguage = (langKey) => {
    setLang(langKey);
  };

  const handleContent = (data) => {
    // copy state to duplicate array
    const _resources = JSON.parse(JSON.stringify(data));
    let _content = {};

    _resources.map((doc) => {
      // set categories based on selected language
      if (doc.id === `categories_${lang}`) {
        setCategories(doc.options);
      }

      if (doc.id === "content_tags") {
        setTags(doc);
      }

      // set content based on selected language
      if (doc.id === "content_global") {
        _content["global"] = doc[lang];
      }
      if (doc.id === "content_whoweare") {
        _content["whoweare"] = doc[lang];
      }
      if (doc.id === "content_contactus") {
        _content["contactus"] = doc[lang];
      }
      if (doc.id === "content_supportlist") {
        _content["supportlist"] = doc[lang];
      }
      if (doc.id === "content_modals") {
        _content["modals"] = doc[lang];
      }
    });

    setContent(_content);
  };

  useEffect(() => {
    setContent({});
    const unsubscribe = firebase
      .firestore()
      .collection("resources")
      .onSnapshot((snapshot) => {
        let requests = [];
        snapshot.forEach((doc) => {
          requests.push({ ...doc.data(), id: doc.id });
        });
        setResources(requests);
        handleContent(requests);
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    handleContent(resources);
  }, [lang]);

  return (
    <ResourcesContext.Provider
      value={{ lang, categories, tags, changeLanguage, content }}
    >
      {props.children}
    </ResourcesContext.Provider>
  );
};

export default ResourcesContextProvider;
