import React, { useState, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { ResourcesContext } from "../contexts/ResourcesContext";
import firebase from "../firebase";

const LanguageSwitcher = () => {
  const { lang, changeLanguage } = useContext(ResourcesContext);
  const [showDropdown, toggleDropdown] = useState(false);
  const dropdownRef = useRef();

  // useEffect(() => {
  //   window.addEventListener("click", handleClickOutside(), true);

  //   return () => window.removeEventListener("click", handleClickOutside(), true);
  // }, []);

  // const handleClickOutside = event => {
  //   // const domNode = ReactDOM.findDOMNode(this);
  //   const domNode = dropdownRef.current;
  //   console.log('handleClickOutside', domNode);

  //   if (!domNode || !domNode.contains(event.target)) {
  //     toggleDropdown(false)
  //   }
  // };

  const updateLanguage = (key) => {
    firebase.analytics().logEvent('website_language', {language: key });
    changeLanguage(key);
    toggleDropdown(!showDropdown);
  };

  const getLabel = () => {
    let _label = "";
    switch (lang) {
      case "english":
        _label = "English";
        break;
      case "sinhala":
        _label = "සිංහල";
        break;
      case "tamil":
        _label = "தமிழ்";
        break;
      default:
        _label = "English";
    }
    return _label;
  };

  return (
    <LanguageButtonContainer>
      <LanguageButton
        className="lang-button"
        onClick={() => toggleDropdown(!showDropdown)}
      >
        <p className="T6">{getLabel()}</p>
        <i className="arrow down"></i>
      </LanguageButton>
      {showDropdown && (
        <Dropdown className="language-dropdown">
          <p className="T6" onClick={() => updateLanguage("english")}>
            English
          </p>
          <p className="T6" onClick={() => updateLanguage("sinhala")}>
            සිංහල
          </p>
          <p className="T6" onClick={() => updateLanguage("tamil")}>
            தமிழ்
          </p>
        </Dropdown>
      )}
    </LanguageButtonContainer>
  );
};

export default LanguageSwitcher;

const LanguageButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;

const LanguageButton = styled.div`
  color: var(--C2);
  border: 1px solid var(--C2);
  border-radius: 8px;
  padding: 8px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  z-index: 2;

  p {
    color: var(--C2);
  }

  .arrow {
    border: solid var(--C2);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 24px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(73, 90, 149, 0.05);
  }
`;

const Dropdown = styled.div`
  position: absolute;
  margin-top: 16px;
  top: 100%;
  margin-top: 4px;
  background-color: var(--C8);
  width: 100%;
  border-radius: 8px;
  z-index: 2;
  border: 1px solid var(--C11);
  box-shadow: 4px 8px 20px rgba(73, 90, 149, 0.15);

  p {
    padding: 12px;
    color: var(--C5);
    /* border-bottom: 0.5px solid var(--C12); */

    &:hover {
      cursor: pointer;
      background-color: var(--C10);
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;
