import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import axios from "axios";

import AuthContextProvider from "./contexts/AuthContext";
import ServiceContextProvider from "./contexts/ServiceContext";
import ResourcesContextProvider from "./contexts/ResourcesContext";
import { Navbar } from "./components/Navbar";
import Footer from "./components/Footer";
import { PrivateRoute } from "./components/PrivateRoute";
import SupportListPage from "./pages/support-list";
import ContactUsPage from "./pages/contact-us";
import AboutUsPage from "./pages/about-us";
import PageNotFound from "./pages/404";
import Login from "./pages/login";
import Register from "./pages/register";
import AddService from "./pages/add-service";

axios.defaults.baseURL =
  "https://us-central1-samana-964d8.cloudfunctions.net/api";

function App() {
  return (
    <Router>
      <ResourcesContextProvider>
        <AuthContextProvider>
          <ServiceContextProvider>
            <Navbar />
            <Switch>
              <Route path="/support-list" component={SupportListPage} />;
              <Route path="/who-we-are" component={AboutUsPage} />
              <Route path="/contact-us" component={ContactUsPage} />
              <Route path="/notfound" component={PageNotFound} />
              <Route path="/admin/login" component={Login} />
              {/* <Route path="/admin/register" component={Register} /> */}
              <Redirect from="/admin/register" to="/admin/login" exact />
              <PrivateRoute
                path="/admin/manage-list"
                component={SupportListPage}
              />
              <PrivateRoute path="/admin/add-service" component={AddService} />
              <Redirect from="/" to="/support-list" exact />
              <Redirect to="/notfound" />
            </Switch>
          </ServiceContextProvider>
          <Footer />
        </AuthContextProvider>
      </ResourcesContextProvider>
    </Router>
  );
}

export default App;
