import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import marked from "marked";

import { ResourcesContext } from "../contexts/ResourcesContext";
import Modal from "../components/CustomModal";

const PrivacyModal = ({ closePrivacyModal }) => {
  const { lang, content } = useContext(ResourcesContext);
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    let PrivacyPolicyEnglish = null;
    if (lang === "english") {
      PrivacyPolicyEnglish = require("../assets/markdown/privacypolicy_english.md");
    } else if (lang === "sinhala") {
      PrivacyPolicyEnglish = require("../assets/markdown/privacypolicy_sinhala.md");
    } else if (lang === "tamil") {
      PrivacyPolicyEnglish = require("../assets/markdown/privacypolicy_tamil.md");
    }
    fetch(PrivacyPolicyEnglish)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMarkdown(marked(text));
      });
  }, []);

  if (content === undefined) {
    return null;
  }
  const _content = content.global;

  return (
    <Modal
      title={_content ? _content.footer_privacy_policy : "Privacy Policy"}
      closeModal={closePrivacyModal}
      width="35%"
      top="4%"
      height="100"
      show={true}
    >
      <ModalBody>
        <div
          className="privacy-content"
          dangerouslySetInnerHTML={{ __html: markdown }}
        ></div>
      </ModalBody>
    </Modal>
  );
};

export default PrivacyModal;

const ModalBody = styled.div`
  height: 100%;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  .privacy-content {
    margin: 0;
    color: var(--C5);
    font-size: 17px;
    font-family: "NotoSans Regular", sans-serif;
    padding-bottom: 36px;

    h2 {
      padding-top: 24px;
      color: var(--C2);
    }

    h2,
    h5 {
      margin-top: 24px;
      color: var(--C2);
    }
    p {
      margin-top: 16px;
      color: var(--C5);
    }
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    .modal-footer {
      justify-content: space-around !important;
    }
  }
  @media screen and (min-width: 768px) {
    .privacy-content {
      margin: 0;
    .modal-footer {
      justify-content: space-around !important;
    }
  }
  }
`;
