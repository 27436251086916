import React from 'react';
import styled from "styled-components";
import Shimmer from './Shimmer';
import SkeletonElement from './SketetonElement';

const SkeletonServiceDetails = () => {
  return (
    <SkeletonWrapper>
      <div className="skeleton-service-details">
        <div className="service-header">
            <SkeletonElement type="service-logo" />
          </div>
        <div className="service-body">
            <SkeletonElement type="service-title" />
            <div className="tags">
              <SkeletonElement type="service-tag" />
              <SkeletonElement type="service-tag" />
            </div>
        </div>
        <div className="service-footer">
            <SkeletonElement type="service-tag" />
            <SkeletonElement type="service-tag" />
            <SkeletonElement type="service-tag" />
        </div>
      </div>
      <Shimmer />
    </SkeletonWrapper>
  )
}

export default SkeletonServiceDetails;

const SkeletonWrapper = styled.div`

  background-color: #ffffff;
  position: relative;
  margin: 8px 0px;
  width: 100%;
  /* padding: 8px 20px; */
  overflow: hidden;

  .service-tag {
    width: 160px;
  }

  .service-footer {
    margin: 10px 24px;

    .service-tag {
      width: 80%;
      margin: 16px 0px;
    }
  }
`;
