import React, { useState, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ResourcesContext } from "../contexts/ResourcesContext";

import LanguageButton from "./LanguageSwitcher";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as EnglishLogo } from "../assets/logos/samana_logo_english.svg";
import { ReactComponent as SinhalaLogo } from "../assets/logos/samana_logo_sinhala.svg";
import { ReactComponent as TamilLogo } from "../assets/logos/samana_logo_tamil.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/social_facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/social_instagram.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/social_linkedin.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/social_twitter.svg";
import PrivacyModal from "../modals/PrivacyModal";
import DisclaimerModal from "../modals/DisclaimerModal";

const Footer = () => {
  const history = useHistory();
  const { lang, content } = useContext(ResourcesContext);
  const location = useLocation();
  const [privacyModal, togglePrivacyModal] = useState(false);
  const [disclaimerModal, toggleDisclaimerModal] = useState(false);

  if (location.pathname === "/support-list") {
    // no footer on support list page
    return null;
  }
  if (content === undefined) {
    return null;
  }
  const _content = content.global;

  const getLogo = () => {
    let logo = (
      <EnglishLogo
        className="logo"
        onClick={() => history.push("/support-list")}
      />
    );
    if (lang === "sinhala") {
      logo = (
        <SinhalaLogo
          className="logo"
          onClick={() => history.push("/support-list")}
        />
      );
    } else if (lang === "tamil") {
      logo = (
        <TamilLogo
          className="logo"
          onClick={() => history.push("/support-list")}
        />
      );
    }

    return logo;
  };

  return (
    <>
      {privacyModal && <PrivacyModal closePrivacyModal={() => togglePrivacyModal(false)} />}
      {disclaimerModal && <DisclaimerModal closeDisclaimerModal={() => toggleDisclaimerModal(false)} />}
      <Container>
        <div className="footer-items grid">
          <div className="samana-col samana-col-1">
            {getLogo()}
            <p className="T7">
              {_content
                ? _content.footer_made_with
                : "Made with ❤️ for Sri Lanka"}
            </p>
            <div className="socials">
              <FacebookIcon
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/samanasrilanka",
                    "_blank"
                  )
                }
              />
              <InstagramIcon
                onClick={() =>
                  window.open("https://www.instagram.com/samana.lk", "_blank")
                }
              />
              {/* <TwitterIcon /> */}
              <LinkedInIcon onClick={() =>
                  window.open("https://www.linkedin.com/company/samana", "_blank")
                }/>
            </div>
          </div>

          <div className="samana-col samana-col-2">
            <p className="T4">
              {_content ? _content.footer_site_map : "SITE MAP"}
            </p>
            <div className="site-map-items">
              <div className="samana-col samana-col-1">
                <FooterLink to={"/support-list"}>
                  {_content ? _content.navbar_support_list : "Support List"}
                </FooterLink>
                <FooterLink to={"/who-we-are"}>
                  {_content ? _content.navbar_who_we_are : "Who We Are"}
                </FooterLink>
                <FooterLink to={"/contact-us"}>
                  {_content ? _content.navbar_contact_us : "Contact Us"}
                </FooterLink>
              </div>
              <div className="samana-col samana-col-2">
                <FooterLink onClick={() => togglePrivacyModal(!privacyModal)}>
                  {_content ? _content.footer_privacy_policy : "Privacy Policy"}
                </FooterLink>
                <FooterLink onClick={() => toggleDisclaimerModal(!disclaimerModal)}>
                  {_content ? _content.footer_terms : "Terms of Service"}
                </FooterLink>
              </div>
            </div>
          </div>

          <div className="samana-col samana-col-3">
            <LanguageButton className="language-button" />
            <p className="T9">
              {_content
                ? _content.footer_copyright
                : "© Samana 2020. All Rights Reserved."}
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;

const FooterLink = styled(Link)`
  color: var(--C2);
  font-size: 14px;
  font-family: "NotoSans SemiBold", sans-serif;
  text-decoration: underline;
  margin-bottom: 10px;
`;

const Container = styled.div`
  background-color: var(--C6);
  color: var(--C2);
  position: relative;
  z-index: 1;

  .heart {
    color: red;
  }

  .footer-items {
    padding: 32px 20px;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1140px;
    margin: 0 auto;

    .samana-col {
      .logo {
        /* width: 118px;
        height: 28px; */
        margin-bottom: 15px;
      }

      .socials {
        margin: 20px 0px;
        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin-right: 15px;
        }
      }

      .site-map-items {
        margin-top: 10px;
        .samana-col {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .samana-col-3 {
      margin-top: 24px;

      .T9 {
        margin-top: 16px;
      }

      .language-dropdown {
        top: -150px;
        width: 150px;
      }
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
    .footer-items {
      grid-template-columns: 1fr 1fr 1fr;

      .samana-col-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .logo {
          margin: 0;
        }

        .socials {
          margin: 0px;
          svg {
            margin-right: 15px;
          }
        }
      }

      .samana-col-3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0px;

        h6 {
          margin: 0px;
        }
      }
    }
  }

  /* Laptop */
  @media screen and (min-width: 960px) {
    .footer-items {
      .samana-col-2 {
        .site-map-items {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
`;
