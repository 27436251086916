import React, { createContext, useState, useEffect } from "react";
import firebase from "../firebase";

export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {
  const [searchParam, setSearchParam] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedService, setSelectedService] = useState({});

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("services")
      .onSnapshot((snapshot) => {
        let requests = [];
        snapshot.forEach((doc) => {
          requests.push({ ...doc.data(), id: doc.id });
        });
        const sortedServices = sortArray(requests);
        setServices(sortedServices);
        console.log("requests", sortedServices);
        setFilteredServices(sortedServices);

        // selected service if service id is in URL
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get("id");

        if (id && requests.length > 0) {
          requests.map((service) => {
            if (service.id === id) {
              setSelectedService(service);
            }
          });
        }
      });

    return () => unsubscribe();
  }, []);

  const sortArray = (array) => {
    return array.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  };

  const selectService = (id) => {
    if (id === "") {
      setSelectedService(id);
    } else {
      filteredServices.map((service) => {
        if (service.id === id) {
          setSelectedService(service);
        }
      });
    }
  };

  const searchService = (searchparam) => {
    // copy state to duplicate array
    const _services = JSON.parse(JSON.stringify(services));

    // convert all service names to lowercase
    const servicesLowercase = [];

    _services.map((service) => {
      const _service = service;
      _service.name = _service.name.toLowerCase();
      servicesLowercase.push(_service);
    });

    // find id that matches search param
    const filteredIds = [];
    servicesLowercase.map((service) => {
      if (service.name.includes(searchparam)) {
        filteredIds.push(service.id);
      }
    });

    // update filteredServices state with matching results
    const filteredArray = [];
    services.map((service) => {
      filteredIds.map((id) => {
        if (service.id === id) {
          filteredArray.push(service);
        }
      });
    });

    setSearchParam(searchparam);
    setFilteredServices(filteredArray.length > 0 ? filteredArray : null); // set null to show could not find service error message 
  };

  const searchCategory = (searchList) => {
    let filteredArray = [];
    if (searchList.length > 0) {
      services.map((service) => {
        if (searchList.includes(service.common.category)) {
          filteredArray.push(service);
        }
      });
    } else {
      filteredArray = services;
    }
    setSelectedCategories(searchList);
    setFilteredServices(filteredArray);
  };

  return (
    <ServiceContext.Provider
      value={{
        filteredServices,
        services,
        selectedService,
        searchParam,
        selectedCategories,
        selectService,
        searchService,
        searchCategory,
      }}
    >
      {props.children}
    </ServiceContext.Provider>
  );
};

export default ServiceContextProvider;
