import React, { useContext } from "react";
import styled from "styled-components";
import { ResourcesContext } from "../contexts/ResourcesContext";

const AccessibilityTag = ({ tagKey }) => {
  const { lang, tags } = useContext(ResourcesContext);
  let text = tagKey;

  switch (tagKey) {
    case "free":
      text = tags.hasOwnProperty("free") ? tags.free[lang] : "Free";
      break;
    case "cost":
      text = tags.hasOwnProperty("cost") ? tags.cost[lang] : "Costs Involved";
      break;
    case "lgbtqia":
      text = tags.hasOwnProperty("lgbtqia")
        ? tags.lgbtqia[lang]
        : "LGBTQIA+ Friendly ‍🏳️‍🌈";
      break;
    case "government_service":
      text = tags.hasOwnProperty("government_service")
        ? tags.government_service[lang]
        : "Government Service";
      break;
    case "counselling_available":
      text = tags.hasOwnProperty("counselling_available")
        ? tags.counselling_available[lang]
        : "Counselling Available";
      break;
  }

  return (
    <Tag className={`tag-${tagKey}`}>
      <p className="T8">{text}</p>
    </Tag>
  );
};

export default AccessibilityTag;

const Tag = styled.div`
  border-radius: 20px;
  display: inline-block;

  p {
    padding: 2px 12px;
    line-height: 20px;
    color: var(--C4);
  }
`;
