import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { ResourcesContext } from "../contexts/ResourcesContext";
import FeedbackModals from "../modals/FeedbackModals";
import firebase from "../firebase";

import { ReactComponent as EditIcon } from "../assets/icons/icon_edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/icon_delete.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/icon_share.svg";
import { ReactComponent as ReportIcon } from "../assets/icons/icon_report.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/icon_phone.svg";
import { ReactComponent as WebsiteIcon } from "../assets/icons/icon_website.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/icon_email_outline.svg";
import { ReactComponent as LocationIcon } from "../assets/icons/icon_location.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/icon_facebook_outline.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/icon_instagram_outline.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/icon_twitter_outline.svg";

import { ServiceContext } from "../contexts/ServiceContext";
import { AuthContext } from "../contexts/AuthContext";

import CategoryTag from "./CategoryTag";
import AccessibilityTag from "./AccessibilityTag";
import Modal from "./CustomModal";
import ShareModal from "../modals/ShareModal";
import SkeletonServiceDetails from "../skeletons/SkeletonServiceDetails";

const ServiceDetails = () => {
  const [modal, toggleModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const { lang, content } = useContext(ResourcesContext);
  const { user } = useContext(AuthContext);
  const { services, selectService, selectedService, filteredServices } = useContext(
    ServiceContext
  );

  const _filteredServices = filteredServices === null ? services : filteredServices; 

  useEffect(()=> {
    setLoading(true);
    setTimeout(()=> {
      setLoading(false);
    }, 1000);
  }, [selectedService])

  if (_filteredServices.length === 0 || loading) {
    return (
      <ServiceDetailsContainer className="service-details">
        <div className="service-header">
          <SkeletonServiceDetails />
        </div>
      </ServiceDetailsContainer>
    );
  }

  const service = selectedService.hasOwnProperty("id")
    ? selectedService
    : _filteredServices[0];

  if (content === undefined) {
    return null;
  }
  const _content = content.supportlist;

  const deleteService = () => {
    const id = localStorage.getItem("FBIdToken");
    console.log(id);
    axios
      .delete(`/services/delete/${service.id}`, {
        headers: {
          Authorization: `Bearer ${id}`,
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const closeModal = () => {
    toggleModal(null);
  };

  const openModal = (type) => {
    if (type === "feedback") {
      toggleModal(
        <FeedbackModals
          show={true}
          serviceId={service.id}
          closeModal={() => toggleModal(false)}
        />
      );
    } else if (type === "phone") {
      toggleModal(
        <Modal
          title={_content ? _content.modal_phone_text : "Phone Numbers"}
          closeModal={closeModal}
          padding="0px"
          show={true}
          footerButtonText={_content ? _content.modal_done_text : "Done"}
          footerButtonClick={closeModal}
        >
          <ModalBody>
            {service.common.phone.map((item, index) => (
              <div
                className="modal-phone-numbers"
                onClick={() =>
                  {
                    firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'phone', phone: service.common.phone[index]});
                    window.open(
                      `tel:${service.common.phone[index].replace(/\s+/g, "")}`
                    )
                  }
                }
                key={index}
              >
                <PhoneIcon className="icon" />
                <p className="T3">{item}</p>
              </div>
            ))}
          </ModalBody>
        </Modal>
      );
    } else if (type === "email") {
      toggleModal(
        <Modal title="Email Addresses" closeModal={closeModal} width="350px"
          show={true}
          footerButtonText={_content ? _content.modal_done_text : "Done"}
          footerButtonClick={closeModal}>
          <ModalBody>
            {service.common.email.map((item, index) => (
              <div
                className="modal-email-addresses"
                onClick={() =>
                  {
                    firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'email', phone: service.common.email[index]});
                    window.open(`mailto:${service.common.email[index]}`)
                  }
                }
                key={index}
              >
                <EmailIcon className="icon" />
                <p className="T3">{item}</p>
              </div>
            ))}
          </ModalBody>
        </Modal>
      );
    } else if (type === "share") {
      toggleModal(
        <ShareModal show={true} service={service} closeModal={() => toggleModal(false)} />
      );
    }
  };

  const getAddress = (address) => {
    let formattedAddress = address.addressLine1;
    if (address.addressLine2) {
      formattedAddress = `${formattedAddress}, ${address.addressLine2}`;
    }
    // if (address.city) {
    //   formattedAddress = `${formattedAddress}, ${address.city}`;
    // }

    return formattedAddress;
  };

  const formatWebsite = (website) => {
    let formattedWebsite = website;
    if (website.includes("http")) {
      formattedWebsite = website.replace(/(^\w+:|^)\/\//, '');
    }

    return formattedWebsite;
  }

  return (
    <>
      {modal}

      <MobileNavBack
        onClick={() => {
          selectService("");
        }}
        className={
          "mobile-nav-back" +
          (selectedService.hasOwnProperty("id") ? " active" : "")
        }
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.04051 14.1008C9.47968 14.5232 9.4935 15.2216 9.07116 15.6611C8.8546 15.8862 8.56522 15.9996 8.27556 15.9996C8.00025 15.9996 7.72495 15.8975 7.51088 15.6917L0.338466 8.79516C0.12219 8.58716 -3.05176e-05 8.2997 -3.05176e-05 7.99957C-3.05176e-05 7.6997 0.12219 7.41226 0.338466 7.20426L7.51088 0.307705C7.95005 -0.11464 8.64881 -0.101117 9.07116 0.338332C9.4935 0.77778 9.47968 1.47626 9.04051 1.89861L2.69542 7.99957L9.04051 14.1008Z"
            fill="black"
          />
        </svg>

        <p className="T3">Support List</p>
      </MobileNavBack>
      <ServiceDetailsContainer
        className={
          "service-details" +
          (selectedService.hasOwnProperty("id") ? " active" : "")
        }
      >
        <div className="service-header">
          <img className="service-logo" src={service.common.logourl} />
          <div className="icons">
            {user ? (
              <Link
                className="edit-icon"
                to={`/admin/add-service?id=${service.id}`}
              >
                <EditIcon />
                <p className="T10">Edit</p>
              </Link>
            ) : (
              <div>
                <button
                  className="share-icon"
                  onClick={() => {
                    console.log(navigator.userAgent);
                    // if (navigator.share) {
                    //   navigator
                    //     .share({
                    //       title: `${service[lang].name}`,
                    //       text: `${service[lang].description}`,
                    //       url: encodeURI(
                    //         `${window.location.href}/id=${service.id}`
                    //       ),
                    //     })
                    //     .then(() => {
                    //       console.log("Thanks for sharing!");
                    //     })
                    //     .catch(console.error);
                    // } else {
                    openModal("share");
                    // }
                  }}
                >
                  <ShareIcon />
                </button>
                <p className="T10">
                  {_content ? _content.share_text : "Share"}
                </p>
              </div>
            )}
            {user ? (
              <div className="delete-icon" onClick={() => deleteService()}>
                <DeleteIcon />
                <p className="T10">Delete</p>
              </div>
            ) : (
              <div>
                <button
                  className="report-icon"
                  onClick={() => {
                  openModal("feedback");
                  firebase.analytics().logEvent('service_reported', {reported_service: service['english'].name})
                }}
                >
                  <ReportIcon />
                </button>
                <p className="T10">
                  {_content ? _content.report_text : "Report"}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="service-body">
          <h2>{service[lang].name}</h2>
          <div className="tags">
            <CategoryTag category={service.common.category} />
            <div className="tag-group">
              <AccessibilityTag tagKey={service.common.cost} />
              {service.common.tags.map((item, index) => (
                <AccessibilityTag key={index} tagKey={item} />
              ))}
            </div>
          </div>
          <p className="B1">{service[lang].description}</p>
        </div>

        <div className="service-footer">
          {service.common.phone.length > 0 && (
            <div className="phone-numbers">
              <div>

              <PhoneIcon className="icon" />
              </div>
              <div className="phone-label">
                {service.common.phone.length > 0 && (
                  <p
                    className="T3"
                    onClick={() =>{                      
                      firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'phone', phone_number: service.common.phone[0]});
                      window.open(
                        `tel:${service.common.phone[0].replace(/\s+/g, "")}`
                      )}
                    }
                  >
                    {service.common.phone[0]}
                  </p>
                )}
                {service.common.phone.length > 1 && (
                  <button
                    className="T5 more-link"
                    onClick={() => openModal("phone")}
                  >{`+ ${service.common.phone.length - 1} ${
                    _content ? _content.more_text : "More"
                  }`}</button>
                )}
              </div>
            </div>
          )}

          {service.common.email.length > 0 && (
            <div className="email">
              <div>
              <EmailIcon className="icon" />

              </div>

              <div className="email-label">
                {service.common.email.length > 0 && (
                  <p
                    className="T3"
                    onClick={() => {
                      firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'email', email: service.common.email[0]});
                      window.open(`mailto:${service.common.email[0]}`)}
                    }
                  >
                    {service.common.email[0]}
                  </p>
                )}
                {service.common.email.length > 1 && (
                  <p
                    className="T5 more-link"
                    onClick={() => openModal("email")}
                  >{`+ ${service.common.email.length - 1} ${
                    _content ? _content.more_text : "More"
                  }`}</p>
                )}
              </div>
            </div>
          )}
          {service.common.website !== "" && (
            <div className="email">
              <div>
              <WebsiteIcon className="icon" />

              </div>

              <div className="email-label">
                  <p
                    className="T3"
                    onClick={() => {
                      firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'website', website: service.common.website});
                      window.open(service.common.website, "_blank")}
                    }
                  >
                    {formatWebsite(service.common.website)}
                  </p>
              </div>
            </div>
          )}
          {service[lang].address.addressLine1 ? (
            <div
              className="address"
              onClick={() => {
                firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'map'});
                window.open(service.common.addressLoc, "_blank")}}
            >
              <>
              <div>

                <LocationIcon className="icon" />
              </div>
                <p className="T3">{getAddress(service[lang].address)}</p>
              </>
            </div>
          ) : null}

          {service.common.socials.facebook &&
          typeof service.common.socials.facebook === "string" ? (
            <div
              className="social social-facebook"
              onClick={() =>
                {
                  firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'facebook'});
                  window.open(service.common.socials.facebook, "_blank")
                }
              }
            >
              <>
                <FacebookIcon className="icon" />
                <p className="T3">
                  {service.common.socials.facebook.split("facebook.com/")[1]}
                </p>
              </>
            </div>
          ) : null}
          {service.common.socials.instagram &&
          typeof service.common.socials.instagram === "string" ? (
            <div
              className="social social-instagram"
              onClick={() =>
                {
                  firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'instagram'});
                  window.open(service.common.socials.instagram, "_blank")
                }
              }
            >
              <>
                <InstagramIcon className="icon" />
                <p className="T3">
                  {service.common.socials.instagram.split("instagram.com/")[1]}
                </p>
              </>
            </div>
          ) : null}
          {service.common.socials.twitter &&
          typeof service.common.socials.twitter === "string" ? (
            <div
              className="social social-twitter"
              onClick={() =>
                {
                  firebase.analytics().logEvent('contact_service', {service_name: service['english'].name, contact_type: 'twitter'});
                  window.open(service.common.socials.twitter, "_blank")
                }
              }
            >
              <>
                <TwitterIcon className="icon" />
                <p className="T3">
                  {service.common.socials.twitter.split("twitter.com/")[1]}
                </p>
              </>
            </div>
          ) : null}
        </div>
      </ServiceDetailsContainer>
    </>
  );
};

export default ServiceDetails;

const MobileNavBack = styled.div`
  border-bottom: 0.5px solid var(--C12);
  cursor: pointer;
  align-items: center;
  background-color: var(--C8);
  height: 64px;
  padding: 0px 20px;

  svg {
    margin-right: 16px;
  }
  /* Laptop */
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const ServiceDetailsContainer = styled.div`
  /* max-width: 630px; */
  padding: 48px 0px;
  background-color: var(--C8);
  width: 100%;
  height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;

  .service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .service-logo {
      height: 64px;
    }

    .icons {
      display: flex;
      align-items: center;

      .report-icon {
        background-color: #f3f4f6;
        height: 40px;
        width: 40px;
        margin-top: 0;
        border-radius: 50%;
        align-content: center;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #edeef2;
        }
      }
      .share-icon {
        background-color: #f3f4f6;
        height: 40px;
        width: 40px;
        margin-top: 0;
        border-radius: 50%;
        align-content: center;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #edeef2;
        }
      }

      div {
        margin: 0px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }
    }
    p {
      text-align: center;
      color: var(--C4);
      margin-top: 12px;
    }
  }

  .service-body {
    padding: 48px 0px 24px 0px;
    border-bottom: 0.5px solid var(--C12);
    color: var(--C5);

    .tags {
      margin: 16px 0px;
      .tag-group {
        margin-top: 8px;
        div {
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .service-footer {
    color: var(--C5);

    & > div {
      margin: 8px 0px;
      padding: 12px 0px;
      display: flex;
      align-items: center;
      &:hover {
          background-color: var(--C10);
      }

      svg {
        margin: 0 16px 0 24px;
      }
    }
    p {
      margin-right: 24px;
    }

    .icon {
      height: 40px;
      width: 40px;
    }

    .more-link {
      margin-right: 24px;
      border-radius: 40px;
      height: 40px;
      width: 128px;
      margin-top: 0;
      border: none;
      display: inline-block;
      padding: 0px 8px;
      text-align: center;
      align-items: center;
      cursor: pointer;
      margin-left: 24px;
      color: var(--C2);
      &:hover {
        border: 1px solid var(--C2);
      }
    }

    .phone-numbers {
      cursor: pointer;
      .phone-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .email {
      cursor: pointer;
      .email-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .address {
      cursor: pointer;
    }

    .address > p {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .social {
      cursor: pointer;
    }
  }

  /* Mobile */
  @media screen and (max-width: 411px) {
    padding: 24px 20px;

    .service-header {
      align-items: flex-start;

      .service-logo {
        max-width: 192px;
        max-height: 64px;
        height: auto;
      }
      .icons {
          margin-left: 24px;
      }
    }
    .service-body {
      padding: 36px 0px 16px 0px;
      .tags {
      margin: 8px 0px 16px 0px;
      }
    }

    .service-footer {
      margin: 16px 0px;

      & > div {
        margin: 16px 0px;
        padding-bottom: 8px;

        svg {
          margin-right: 12px;
          margin-left: 0px;
        }
      }
    }
  }

  /* Mobile & larger */
  @media screen and (min-width: 411px) {
    .service-header {
      margin: 0 24px;
    }
    .service-body {
      margin: 0 24px;
    }
    .service-footer {

      & > div {
        svg {
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }
  }
`;

const ModalBody = styled.div`
  .modal-phone-numbers,
  .modal-email-addresses {
    margin: 8px 0px;
    display: flex;
    color: var(--C5);
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;

      .icon {
        margin-left: 20px;
      }

    svg {
      margin-right: 16px;
    }
    &:hover {
        background-color: var(--C10);
      }
  }
`;
