import React from "react";
import styled from "styled-components";

const Button = ({ text, onClick, type }) => {
  return (
    <Container className="button" onClick={onClick} type={type}>
      <p className="T5">{text}</p>
    </Container>
  );
};

export default Button;

const Container = styled.div`
  border: ${(props) => (props.type === "link" ? 0 : "1px solid var(--C2)")};
  background-color: var(--C8);
  border-radius: 8px;
  padding: 0 16px;
  min-width: 148px;
  margin-right: ${(props) => (props.type === "link" ? "8px" : 0)};
  height: 48px;
  display: flex;
  align-items: center;
  text-decoration: ${(props) => (props.type === "link" ? "underline" : "none")};
  justify-content: center;
  /* width: 196px; */
  cursor: pointer;

  p {
    color: ${(props) => (props.type === "link" ? "var(--C4)" : "var(--C2)")};
    opacity: ${(props) => (props.type === "link" ? 0.8 : "none")};
  }

  &:hover {
    border: ${(props) => (props.type === "link" ? 0 : "1px solid var(--C2)")};
    background-color: ${(props) =>
      props.type === "link" ? "var(--C10)" : "var(--C2)"};
    p {
      color: ${(props) => (props.type === "link" ? "var(--C2)" : "var(--C8)")};
      opacity: ${(props) => (props.type === "link" ? 1 : "none")};
    }
  }
`;
