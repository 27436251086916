import React, { useState, useContext } from "react";
import styled from "styled-components";

import { ResourcesContext } from "../contexts/ResourcesContext";

import { ReactComponent as FacebookIcon } from "../assets/icons/social_facebook.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/social_twitter.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/share_link.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/share_email.svg";
import { ReactComponent as MessengerIcon } from "../assets/icons/share_messenger.svg";
import { ReactComponent as WhatsAppIcon } from "../assets/icons/share_whatsapp.svg";
import { ReactComponent as SMSIcon } from "../assets/icons/share_sms.svg";
import Modal from "../components/CustomModal";
import firebase from "../firebase";

const ShareModal = ({ show, service, closeModal }) => {
  const { lang, content } = useContext(ResourcesContext);
  const [copyText, setCopyText] = useState(content ? content.modals.share_copylink_label : "Copy Link");

  if (content === undefined) {
    return null;
  }
  const _content = content.modals;

  const title = encodeURI(`${service[lang].name}`);
  const url = encodeURI(`${window.location.href}?id=${service.id}`);

  const handleShare = (type) => {
    window.navigator.clipboard.writeText(
      `${window.location.href}?id=${service.id}`
    );
    console.log("copied");
    setCopyText(_content ? _content.share_copiedlink_label : "Link Copied");
  };

  return (
    <Modal
      title={_content ? _content.share_title : "Share"}
      closeModal={closeModal}
      show={show}
      padding="0px"
      footerButtonText={_content ? _content.modal_done_text : "Done"}
      footerButtonClick={closeModal}
    >
      <ModalBody>
        <div className="share-options">
          <a
            className="share-option"
            href={`https://www.facebook.com/sharer.php?u=${url}`}
            onClick={() => {firebase.analytics().logEvent('share_service', {service_name: service['english'].name, share_method: 'facebook' });}}
            target="_blank"
          >
            <FacebookIcon />
            <p className="T3">{_content ? _content.share_facebook_label : "Facebook"}</p>
          </a>
          <a
            className="share-option"
            href={`https://twitter.com/share?url=${url}&text=Check out this service called ${title}&via=Samana`}
            onClick={() => {firebase.analytics().logEvent('share_service', {service_name: service['english'].name, share_method: 'twitter' });}}
            target="_blank"
          >
            <TwitterIcon />
            <p className="T3">{_content ? _content.share_twitter_label : "Twitter"}</p>
          </a>
          <a className="share-option" onClick={() => {
              firebase.analytics().logEvent('share_service', {service_name: service['english'].name, share_method: 'copy' });
              handleShare("copy")
            }}>
            <CopyIcon />
            <p className="T3">{copyText}</p>
          </a>
          <a
            className="share-option"
            href={`mailto:?subject=${title}&body=Check out this service called ${title}: ${url}%20`}
            onClick={() => {firebase.analytics().logEvent('share_service', {service_name: service['english'].name, share_method: 'email' });}}
            target="_blank"
          >
            <EmailIcon />
            <p className="T3">{_content ? _content.share_email_label : "Email"}</p>
          </a>
          {/* <a className="share-option">
            <MessengerIcon />
            <p className="T3">Messager</p>
          </a> */}
          <a
            className="share-option"
            href={`https://api.whatsapp.com/send?text=Check out this service called ${title}: ${url}`}
            onClick={() => {firebase.analytics().logEvent('share_service', {service_name: service['english'].name, share_method: 'whatsapp' });}}
            target="_blank"
          >
            <WhatsAppIcon />
            <p className="T3">{_content ? _content.share_whatsapp_label : "Whatsapp"}</p>
          </a>
          <a
            className="share-option"
            href={
              navigator.userAgent.includes("iPhone") ||
              navigator.userAgent.includes("iPad")
                ? `sms:&body=Check out this service called ${title}: ${url}`
                : `sms:?body=Check out this service called ${title}: ${url}`
            }
            onClick={() => {firebase.analytics().logEvent('share_service', {service_name: service['english'].name, share_method: 'sms' });}}
            target="_blank"
          >
            <SMSIcon />
            <p className="T3">{_content ? _content.share_sms_label : "SMS"}</p>
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;

const ModalBody = styled.div`

  .share-options {
    display: flex;
    flex-direction: column;
    margin: 8px 0;

    .share-option {
      display: flex;
      align-items: center;
      padding: 12px 0px;
      color: var(--C5);

      &:visited {
        color: var(--C5);
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        background-color: var(--C10);
      }
      svg {
        margin: 0 24px;

      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-footer {
      justify-content: flex-end !important;
    }
  }
`;