import React from "react";
import styled, {keyframes} from "styled-components";

const Shimmer = () => {
  return (
    <ShimmerWrapper>
      <div className="shimmer"></div>
    </ShimmerWrapper>
  )
}

export default Shimmer;


const loading = keyframes `{
  0% { transform: translateX(-120%)}
  50% { transform: translateX(-60%)}
  100% { transform: translateX(120%)}
}`;

const ShimmerWrapper = styled.div`
  /* animations for skeleton */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${loading} 2.0s infinite;

  .shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255,255,255, 0.05);
  }
`;
