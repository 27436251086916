import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";

import { ResourcesContext } from "../contexts/ResourcesContext";

import Modal from "../components/CustomModal";
import Spinner from "../components/Spinner";
import Dropdown from "../components/Dropdown";

const ContactModals = ({ type, show, closeModal }) => {
  const { content } = useContext(ResourcesContext);
  
  const categoryOptions = [
    { label: content.modals ? content.modals.contact_options_feedback : "Suggestions & Feedback", key: "feedback" },
    { label: content.modals ? content.modals.contact_options_volunteer : "Become a Volunteer", key: "volunteer" },
    { label: content.modals ? content.modals.contact_options_partner : "Partner With Us", key: "partner" },
  ];
  const getDefaultCategory = () => {
    let category = 'feedback';
    categoryOptions.map((item) => {
      if (item.key === type) {
        category = item.key;
      }
    });

    return category
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState(type ? getDefaultCategory() : null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  if (content === undefined) {
    return null;
  }
  const _content = content.modals;

  const sendEmail = () => {
    console.log("submit feedback", name, email, category, message);
    if (name === "" || email === "" || message === "") {
      setErrorMessage("Please fill out all fields in the form");
      return null;
    }
    setLoading(true);

    let categoryLabel = category;
    categoryOptions.map((item) => {
      if (item.key === type) {
        categoryLabel = item.label;
      }
    });

    const payload = {
      name,
      email,
      category: categoryLabel,
      body: message,
    };

    axios
      .post("/email/contactus", payload)
      .then((res) => {
        console.log(`email sent`);
        setLoading(false);
        setErrorMessage("");
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
        window.scrollTo(0, 0);
        const error = _content ? _content.contact_error_message : "Oops! Looks like there was a problem. Please try again or reach out to us at reachsamana@gmail.com";
        setErrorMessage(error);
      });
  };


  return (
    <Modal
      title={_content ? _content.contact_title : "Contact Form"}
      closeModal={closeModal}
      width="650px"
      top="5%"
      show={show}
      footerLinkText={_content ? _content.contact_button_cancel : "Cancel"}
      footerLinkClick={closeModal}
      footerButtonText={_content ? _content.contact_button_send : "Send"}
      footerButtonClick={() => {console.log('senclicked'); sendEmail()}}
      loading={loading}
    >
      <ModalBody>
        {loading ? <Spinner /> : 
        <>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <div className="contact-form-content" id="ContactForm">
          <input
            type="text"
            name={name}
            required
            placeholder={
              _content ? _content.contact_placeholder_name : "Your Name"
            }
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name={email}
            required
            placeholder={
              _content ? _content.contact_placeholder_email : "Email Address"
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Dropdown
            name="category"
            className="categories"
            options={categoryOptions}
            value={category}
            handleSelect={(optionKey) => {
              setCategory(optionKey);
            }}
          />
          <textarea
            className="message"
            rows="8"
            cols="50"
            name={message}
            placeholder={
              _content ? _content.contact_placeholder_message : "Message..."
            }
            value={message}
            required="required"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        </>}
      </ModalBody>
    </Modal>
  );
};

export default ContactModals;

const ModalBody = styled.div`
`;

const ErrorMessage = styled.p`
  font-size: 15px;
  font-family: "NotoSans", sans-serif;
  color: red;
  text-align: center;
`;
