import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../firebase";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  let history = useHistory();

  const [token, setToken] = useState(localStorage.getItem("FBIdToken"));
  const [user, setAuth] = useState(async () =>
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("AuthContext loaded --- isAuth");
        return user;
      } else {
        console.log("AuthContext loaded --- isAuth false");
        return null;
      }
    })
  );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("AuthContext loaded --- isAuth");
        setAuth(user);
        if (token === null) {
          console.log("token -- useEffect state change", token);
        }
      } else {
        console.log("AuthContext loaded --- isAuth false");
        setAuth(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const register = (email, password) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((data) => {
        setAuth(data.user);
        return data.user.getIdToken();
      })
      .then((token) => {
        setToken(token);
        localStorage.setItem("FBIdToken", token);
        history.push("/admin/manage-list");
      });
  };

  const login = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        setAuth(data.user);
        return data.user.getIdToken();
      })
      .then((token) => {
        setToken(token);
        localStorage.setItem("FBIdToken", token);
        history.push("/admin/manage-list");
      })
      .catch((error) => {
        setAuth(null);
        console.log("error", error.message);
      });
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        setToken(null);
        localStorage.removeItem("FBIdToken");
        setAuth(null);
        console.log("successfully logged out");
      })
      .catch(function (error) {
        // An error happened.
        console.log("error", error.message);
      });
  };

  return (
    <AuthContext.Provider value={{ user, register, login, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
