import React from 'react';
import styled from "styled-components";
import Shimmer from './Shimmer';
import SkeletonElement from './SketetonElement';

const SkeletonServiceList = () => {
  return (
    <SkeletonWrapper>
      <div className="skeleton-service-list">
          <SkeletonElement type="service-title" />
            <SkeletonElement type="service-tag" />
            <SkeletonElement type="service-tag" />
      </div>
      <Shimmer />
    </SkeletonWrapper>
  )
}

export default SkeletonServiceList;

const SkeletonWrapper = styled.div`
  background-color: #ffffff;
  position: relative;
  margin: 8px 0px;
  padding: 8px 20px;
  overflow: hidden;
  border-bottom: 0.5px solid var(--C12);
`;
