import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyD8IbCRL6LuPfdh7-TpEwR2NiLMxvsbZDM",
  authDomain: "samana-964d8.firebaseapp.com",
  databaseURL: "https://samana-964d8.firebaseio.com",
  projectId: "samana-964d8",
  storageBucket: "samana-964d8.appspot.com",
  messagingSenderId: "1045963292700",
  appId: "1:1045963292700:web:67e88dc9d78f3c14d35fd9",
  measurementId: "G-6BM6WQ5L97",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.functions();
firebase.storage();

export default firebase;
