import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ResourcesContext } from "../contexts/ResourcesContext";
import { ServiceContext } from "../contexts/ServiceContext";
import { ReactComponent as SearchIcon } from "../assets/icons/icon_search.svg";

import FeedbackModals from "../modals/FeedbackModals";
import MultiSelect from "./MultiSelect";

const SearchServices = ({ headerSize }) => {
  const { lang, categories, content } = useContext(ResourcesContext);
  const { selectedService, searchService, searchCategory, selectedCategories } = useContext(
    ServiceContext
  );

  const [modal, toggleModal] = useState(false);
  const [showMultiSelectModal, toggleMultiSelectModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchCategories, setSearchCategories] = useState(categories);

  if (content === undefined) {
    return null;
  }
  const _content = content.supportlist;

  const getCategoryDefaultMobileLabel = () => {
    let label = "All Services";
    if (lang === "english") {
      label = "All Services";
    } else if (lang === "sinhala") {
      label = "සියලුම සේවාවන්";
    } else if (lang === "tamil") {
      label = "அனைத்து சேவைகளும்";
    }

    return label;
  }

  const getCategoryDefaultDesktopLabel = () => {
    let label = "All Services";
    if (categories.length === searchCategories.length || searchCategories.length === 0) {
      if (lang === "english") {
        label = "All Services";
      } else if (lang === "sinhala") {
        label = "සියලුම සේවාවන්";
      } else if (lang === "tamil") {
        label = "அனைத்து சேவைகளும்";
      }
    } else if (searchCategories.length === 1) {
      label = categories.map((item) => {
        if (item.key === searchCategories[0]) {
          return item.label;
        }
      })
    } else if (searchCategories.length > 1) {
      if (lang === "english") {
        label = "Multiple Selected";
      } else if (lang === "sinhala") {
        label = "බහු විකල්ප තෝරා ඇත";
      } else if (lang === "tamil") {
        label = "பல தேர்ந்தெடுக்கப்பட்டன";
      }
    }

    return label;
  };

  return (
    <PageContainer>
      {showMultiSelectModal && (
        <MultiSelect
          showModal={true}
          label={getCategoryDefaultMobileLabel()}
          options={categories}
          selectedCategories={selectedCategories}
          handleModal={(showMultiSelectModal) =>
            toggleMultiSelectModal(showMultiSelectModal)
          }
          handleSubmit={(list) => {
            toggleMultiSelectModal(false);
            searchCategory(list);
          }}
        />
      )}
      {modal && <FeedbackModals show={true} closeModal={() => toggleModal(false)} />}
      <Container
        id="SearchServices"
        className={selectedService.hasOwnProperty("id") ? " hidden" : ""}
      >
        <div className="search-service">
          <h4
            className={
              headerSize === "small"
                ? "search-title hide-header"
                : "search-title show-header"
            }
          >
            {_content
              ? _content.banner_header
              : "Browse through our list of support services available in Sri Lanka."}
          </h4>
          <div
            className={
              headerSize === "small"
                ? "feedback-text hide-header"
                : "feedback-text show-header"
            }
          >
            <p className="B2">
              {_content
                ? _content.banner_feedback_text
                : "Have you used any services on this list?"}
            </p>
            <p className="T9 underline" onClick={() => toggleModal(!modal)}>
              {_content
                ? _content.banner_feedback_link
                : "We'd love to hear how it went, anonymously."}
            </p>
          </div>

          <div className="search-bar">
            <input
              className="search-input"
              type="text"
              name={search}
              placeholder={_content ? _content.search_placeholder_text : "Search"}
              onChange={(e) => searchService(e.target.value)}
            />
            {categories.length > 1 ? (
              <MultiSelect
                className="search-categories"
                label={getCategoryDefaultDesktopLabel()}
                options={categories}
                selectedCategories={selectedCategories}
                handleModal={(showMultiSelectModal) =>
                  toggleMultiSelectModal(showMultiSelectModal)
                }
                handleSubmit={(list) => {
                  setSearchCategories(list);
                  searchCategory(list);
                }}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </PageContainer>
  );
};

export default SearchServices;

const PageContainer = styled.div`
  .modal-background {
    overflow: hidden;
  }
`;

const Container = styled.div`
  background-color: var(--C1);
  box-shadow: 0px 4px 10px -8px rgba(73, 90, 149, 0.3);
  position: fixed;
  /* top: 64px; */
  /* position: relative; */
  z-index: 1;

  @media screen and (min-width: 768px) {
    /* Tablet & Larger*/
    position: relative;
    top: 0px;
  }

  .search-service {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    padding: 0px 20px 16px 20px;
    /* max-width: 1440px; */
    color: var(--C2);

    .show-header {
      transition: all 0.1s ease;
    }

    .hide-header {
      opacity: 0;
      height: 0px;
      margin-bottom: 0px;
      transition: all 0.1s ease;
    }

    h4 {
      font-weight: 600;
      letter-spacing: -0.026em;
      margin-bottom: 8px;
    }

    .search-title {
      grid-column: 1/5;
      grid-row: 1;
    }

    .feedback-text {
      grid-column: 1/5;
      grid-row: 2;
      margin-bottom: 16px;
      &.hide-header {
        margin-bottom: 0;
      }

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .search-bar {
      grid-column: 1/5;
      grid-row: 3;
      display: grid;
      background-color: var(--C8);
      border-radius: 8px;
      grid-template-columns: repeat(4, 1fr);
      filter: drop-shadow(2px 4px 20px rgba(73, 90, 149, 0.2));

      .search-input {
        grid-column: 1/4;
        grid-row: 1;
        height: 48px;
        margin: 0;
        border: none;
        border-right: 1px solid var(--C11);
        border-radius: 8px 0 0 8px;
      }

      .search-categories {
        grid-column: 4/5;
        grid-row: 1;
        height: 48px;
        margin: 0;
        border: none;
        .select-button,
        .select-button-mobile {
          border-radius: 0 8px 8px 0;
          padding: 0 16px;
          margin-right: 8px;
        }
      }
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
    .search-service {
      grid-template-columns: repeat(8, 1fr);

      .search-title {
        grid-column: 1/9;
        grid-row: 1;
      }

      .feedback-text {
        grid-column: 1/9;
        grid-row: 2;

        .B2 {
          display: inline-block;
          margin-right: 8px;
        }
      }

      .search-bar {
        grid-template-columns: repeat(8, 1fr);
        grid-column: 1/9;
        grid-row: 3;

        .search-input {
          grid-column: 1/5;
          height: 64px;
        }

        .search-categories {
          grid-column: 5/9;
          height: 64px;
        }
      }
    }
  }

  /* Laptop */
  @media screen and (min-width: 960px) {
    .search-service {
      grid-template-columns: repeat(12, 1fr);
      padding-left: 70px;
      padding-right: 70px;

      h4 {
        font-weight: 700;
        letter-spacing: 0em;
      }

      .search-title {
        grid-column: 1/9;
        grid-row: 1;
      }

      .feedback-text {
        text-align: right;
        align-self: end;
        grid-column: 9/13;
        grid-row: 1;
      }

      .search-bar {
        grid-template-columns: repeat(12, 1fr);
        grid-column: 1/13;
        grid-row: 2;

        .search-input {
          grid-column: 1/9;
          height: 64px;
        }

        .search-categories {
          grid-column: 9/13;
          height: 64px;
        }
      }
    }
  }
`;
