import React, { useState, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../contexts/AuthContext";
import { ReactComponent as EnglishLogo } from "../assets/logos/samana_logo_english.svg";
import Button from "../components/Button";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <Container>
      <div className="form-container">
        <EnglishLogo className="logo" />
        <form className="login" onSubmit={handleSubmit}>
          <input
            type="text"
            name={email}
            required
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name={password}
            required
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button 
          className="button" 
          text={"Sign In"} 
          onClick={handleSubmit} />
        </form>
      </div>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  background-color: var(--C10);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);

  @media screen and (max-width: 768px) {
    /* Tablet */
    background-color: var(--C8);
  }

  .form-container {
    background-color: var(--C8);
    border-radius: 16px;
    border: 0.5px solid var(--C11);
    box-shadow: 2px 4px 20px rgba(73, 90, 149, 0.05);
    width: 450px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      /* Tablet */
      box-shadow: none;
      border-radius: 0;
    }

    .logo {
      margin-bottom: 20px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
      }

      .button {
        margin-top: 36px;
      }
    }
  }
`;
