import React from "react";
import styled from "styled-components";

class TextGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: this.getDefaultValues(),
    };
  }

  getDefaultValues = () => {
    if (this.props.values) {
      // loop thru values
      console.log("getDefaultValues - values", this.props.values);
      return this.props.values;
    } else {
      return this.props.defaultDisplay === 1 ? [""] : [];
    }
  };

  addMore = () => {
    const _values = this.state.values;
    _values.push("");
    this.setState({ values: _values });
  };

  handleBlur = () => {
    // TODO - Validation
    this.props.addToState(this.state.values, this.props.type);
  };

  render() {
    return (
      <div className="text-group">
        {this.state.values.map((item, index) => (
          <input
            key={`${this.props.label}-${index}`}
            type="text"
            placeholder={this.props.label}
            value={item}
            onChange={(e) => {
              const _values = this.state.values;
              _values[index] = e.target.value;
              this.setState({ values: _values });
            }}
            onBlur={this.handleBlur}
          />
        ))}
        <AddMore onClick={this.addMore}>
          <span className="add-button">+</span>{" "}
          <p className="T6">{this.props.label}</p>
        </AddMore>
      </div>
    );
  }
}

export default TextGroup;

const AddMore = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .add-button {
    background-color: #f5f5f5;
    color: #1f2f53;
    font-size: 24px;
    font-family: "NotoSans Bold", sans-serif;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    margin-left: 10px;
  }
`;
