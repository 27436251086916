import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ResourcesContext } from "../contexts/ResourcesContext";
import { ReactComponent as Feedback } from "../assets/images/contact_feedback_image.svg";
import { ReactComponent as Volunteer } from "../assets/images/contact_volunteer_image.svg";
import { ReactComponent as Partner } from "../assets/images/contact_partner_image.svg";

import ContactModals from "../modals/ContactModals";

const ContactUsPage = () => {
  const { content } = useContext(ResourcesContext);
  const [modal, toggleModal] = useState(null);

  if (content === undefined) {
    return null;
  }
  const _content = content.contactus;

  const openModal = (type) => {
    toggleModal(
      <ContactModals show={true} type={type} closeModal={() => toggleModal(null)} />
    );
  };

  return (
    <>
      {modal}
      <Container>
        <section className="banner">
          <h4>{_content ? _content.banner_heading : "Get In Touch"}</h4>
          <h5>
            {_content
              ? _content.banner_line_1
              : "Pick a category to send us a message."}
          </h5>
          <h5>
            {_content
              ? _content.banner_line_2
              : "We will respond as soon as possible."}
          </h5>
          <div className="cards grid">
            <div
              className="card feedback"
              onClick={() => openModal("feedback")}
            >
              <div className="card-image">
                <Feedback />
              </div>
              <div className="card-text">
                <h5>
                  {_content
                    ? _content.feedback_card_header
                    : "Suggestions & Feedback"}
                </h5>
                <p className="B2">
                  {_content
                    ? _content.feedback_card_text
                    : "Tell us how we can improve"}
                </p>
              </div>
            </div>

            <div
              className="card volunteer"
              onClick={() => openModal("volunteer")}
            >
              <div className="card-image">
                <Volunteer />
              </div>
              <div className="card-text">
                <h5>
                  {_content
                    ? _content.volunteer_card_header
                    : "Become a Volunteer"}
                </h5>
                <p className="B2">
                  {_content
                    ? _content.volunteer_card_text
                    : "Helping hands are always welcome"}
                </p>
              </div>
            </div>

            <div className="card partner" onClick={() => openModal("partner")}>
              <div className="card-image">
                <Partner />
              </div>
              <div className="card-text">
                <h5>
                  {_content ? _content.partner_card_header : "Partner With Us"}
                </h5>
                <p className="B2">
                  {_content
                    ? _content.partner_card_text
                    : "We want to work with you"}
                </p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default ContactUsPage;

const Container = styled.div`
  position: relative;
  top: 64px;
  color: var(--C2);
  text-align: center;
  z-index: 1;
  margin-bottom: 80px;

  
  @media screen and (min-width: 768px) {
    /* Tablet & Larger*/  
    margin-bottom: 0px;
    top: 0px;
  }

  .banner {
    background: linear-gradient(
      to bottom,
      var(--C1) 0%,
      var(--C1) 25%,
      var(--C8) 25%,
      var(--C8) 100%
    );
    padding: 48px 20px;
    h4 {
      margin-bottom: 16px;
    }
  }

  .cards {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 36px;
    justify-items: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1140px;
    position: relative;
    z-index: 1;

    .card {
      border-radius: 16px;
      background-color: var(--C8);
      box-shadow: 2px 4px 20px rgba(73, 90, 149, 0.15);
      border: 0.5px solid rgba(0, 0, 0, 0.3);
      min-height: 350px;
      width: 300px;
      box-sizing: border-box;
      margin-top: 24px;
      border: 0.5px solid #B8BDC8;

      &:hover {
        cursor: pointer;
        box-shadow: 4px 8px 20px rgba(73, 90, 149, 0.25);
      }

      .card-image {
        height: 250px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .card-text {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 16px;
        align-items: center;

        .B2 {
          margin-top: 10px;
        }
      }
    }

    .feedback {
      .card-image {
        background-color: #CFFBE3;
      }
    }
    .volunteer {
      .card-image {
        background-color: #F9EFB4;
      }
    }
    .partner {
      .card-image {
        background-color: #D3FBFB;
      }
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
  }

  /* Laptop */
  @media screen and (min-width: 960px) {
    min-height: calc(100vh - 245px);

    .banner {
      background: var(--C1);
      padding-bottom: 200px;
      position: relative;
      height: 650px;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 530px;
        background-image: url("wave_white.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .cards {
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 0px;
      justify-items: center;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 40px;

      .card {
        width: 280px;
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: 1140px) {
    .banner {
    padding-top: 64px;
    
    h5 {
      font-weight: 500;
    }
    }
    .cards {
      padding-left: 70px;
      padding-right: 70px;

      .card {
        width: 300px;
      }
    }
  }
`;
