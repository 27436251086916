import React, { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";

import { ResourcesContext } from "../contexts/ResourcesContext";
import { AuthContext } from "../contexts/AuthContext";
import { ServiceContext } from "../contexts/ServiceContext";

import LanguageButton from "./LanguageSwitcher";
import { ReactComponent as EnglishLogo } from "../assets/logos/samana_logo_english.svg";
import { ReactComponent as SinhalaLogo } from "../assets/logos/samana_logo_sinhala.svg";
import { ReactComponent as TamilLogo } from "../assets/logos/samana_logo_tamil.svg";
import { ReactComponent as HamburgerIcon } from "../assets/icons/icon_hamburger.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/icon_close.svg";

export const Navbar = () => {
  const history = useHistory();
  const { user, logout } = useContext(AuthContext);
  const { lang, content } = useContext(ResourcesContext);
  const { selectedService } = useContext(ServiceContext);
  const [showMobileNav, toggleMobileNav] = useState(false);

  if (content === undefined) {
    return null;
  }
  const _content = content.global;

  const getLogo = () => {
    let logo = (
      <EnglishLogo
        className="logo"
        onClick={() => history.push("/support-list")}
      />
    );
    if (lang === "sinhala") {
      logo = (
        <SinhalaLogo
          className="logo"
          onClick={() => history.push("/support-list")}
        />
      );
    } else if (lang === "tamil") {
      logo = (
        <TamilLogo
          className="logo"
          onClick={() => history.push("/support-list")}
        />
      );
    }

    return logo;
  };

  return (
    <>
      <NavContainer
        className={selectedService.hasOwnProperty("id") ? " hidden" : ""}
      >
        <div className="navbar">
          <div className="nav-items">
            {getLogo()}
            <NavItem to="/support-list">
              {_content ? _content.navbar_support_list : "Support List"}
            </NavItem>
            {user ? (
              <>
                <NavItem to="/who-we-are">
                  {_content ? _content.navbar_who_we_are : "Who We Are"}
                </NavItem>
                <NavItem to="/contact-us">
                  {_content ? _content.navbar_contact_us : "Contact Us"}
                </NavItem>
                <NavItem to="/admin/add-service">
                  {_content ? _content.navbar_add_service : "Add a Service"}
                </NavItem>
              </>
            ) : (
                <>
                  <NavItem to="/who-we-are">
                    {_content ? _content.navbar_who_we_are : "Who We Are"}
                  </NavItem>
                  <NavItem to="/contact-us">
                    {_content ? _content.navbar_contact_us : "Contact Us"}
                  </NavItem>
                </>
              )}
          </div>

          <div className="nav-right">
            {user ? (
              <>
                <NavItem to="/admin/login" onClick={() => logout()}>
                  {_content ? _content.navbar_logout : "Logout"}
                </NavItem>

                <LanguageButton />
              </>
            ) : (
                <LanguageButton />
              )}
          </div>

          <div className="mobile-nav">
            {getLogo()}
            <div
              className="nav-hamburger"
              onClick={() => toggleMobileNav(!showMobileNav, window.scrollTo(0, 0))}
            >
              <HamburgerIcon />
            </div>
          </div>
        </div>
      </NavContainer>
      {showMobileNav && (
        <MobileNav>
          <CloseIcon onClick={() => toggleMobileNav(!showMobileNav)} />
          <LanguageButton />
          {user ? (
            <>
              <NavItem
                  to="/support-list"
                  onClick={() => toggleMobileNav(!showMobileNav, window.scrollTo(0, 0))}
                >
                {_content ? _content.navbar_support_list : "Support List"}
              </NavItem>
              <NavItem
                to="/who-we-are"
                onClick={() => toggleMobileNav(!showMobileNav, window.scrollTo(0, 0))}
              >
                {_content ? _content.navbar_who_we_are : "Who We Are"}
              </NavItem>
              <NavItem
                to="/contact-us"
                onClick={() => toggleMobileNav(!showMobileNav, window.scrollTo(0, 0))}
              >
                {_content ? _content.navbar_contact_us : "Contact Us"}
              </NavItem>
              <NavItem
                to="/admin/add-service"
                onClick={() => toggleMobileNav(!showMobileNav)}
              >
                {_content ? _content.navbar_add_service : "Add a Service"}
              </NavItem>
              <NavItem
                to="/admin/login"
                onClick={() => {
                  toggleMobileNav(!showMobileNav);
                  logout();
                }}
              >
                {_content ? _content.navbar_logout : "Logout"}
              </NavItem>
            </>
          ) : (
              <>
                <NavItem
                  to="/support-list"
                  onClick={() => toggleMobileNav(!showMobileNav)}
                >
                  {_content ? _content.navbar_support_list : "Support List"}
                </NavItem>
                <NavItem
                  to="/who-we-are"
                  onClick={() => toggleMobileNav(!showMobileNav)}
                >
                  {_content ? _content.navbar_who_we_are : "Who We Are"}
                </NavItem>
                <NavItem
                  to="/contact-us"
                  onClick={() => toggleMobileNav(!showMobileNav)}
                >
                  {_content ? _content.navbar_contact_us : "Contact Us"}
                </NavItem>
              </>
            )}
        </MobileNav>
      )}
    </>
  );
};

const NavItem = styled(NavLink)`
  font-size: 14px;
  font-family: "NotoSans Regular", "NotoSansSinhala Regular", "NotoSansTamil Regular", Helvetica, sans-serif;
  font-weight: 600;
  color: var(--C2);
  opacity: 60%;

  &:hover {
    cursor: pointer;
    background-color: rgba(78, 89, 123, 0.1);
    padding: 10px 24px;
    border-radius: 20px;
    opacity: 100%;
    color: var(--C2);
    text-decoration: none;
  }
`;

const NavContainer = styled.nav`
  background-color: var(--C1);
  height: 64px;
  position: fixed;
  z-index:2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Smaller than laptops - hide navbar on mobile view of Service Details*/
  @media screen and (max-width: 960px) {
    &.hidden {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    /* Tablet & Larger*/
    position: relative;
  }

  .navbar {
    /* max-width: 1440px; */
    width: 100%;
    padding: 0px 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      cursor: pointer;
    }

    .nav-items {
      display: grid;
      grid-template-columns: 130px repeat(4, minmax(20px, auto));
      align-items: center;

      a {
        justify-self: center;
        padding: 8px 24px;
      }
      .active {
        opacity: 100%;
        outline: none;
      }
    }

    .nav-right {
      display: flex;
      align-items: center;
      a {
        margin-right: 40px;
      }
    }

    .mobile-nav {
      display: none;
    }

    @media screen and (max-width: 960px) {
      padding: 0 20px;
    }

    @media screen and (max-width: 768px) {
      /* Tablet */
      .nav-items,
      .nav-right {
        display: none;
      }

      .mobile-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }
`;

const MobileNav = styled.div`
  font-size: 14px;
  font-family: "NotoSans Regular", "NotoSansSinhala Regular", "NotoSansTamil Regular", Helvetica, sans-serif;
  font-weight: 600;
  color: var(--C2);
  background-color: var(--C1);
  position: fixed;
  top: 0%;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 60px repeat(3,70px);
  grid-gap: 20px;
  align-items: center;
  padding: 0px 20px;
  z-index: 99;

  svg {
    filter: invert(32%) sepia(88%) saturate(313%) hue-rotate(187deg) brightness(91%) contrast(91%);
  }

  a {
    grid-column: 1 / -1;
    font-size: 28px;
    font-family: "NotoSans Regular", "NotoSansSinhala Regular", "NotoSansTamil Regular", Helvetica, sans-serif;
    font-weight: 600;
  }

  .active {
    opacity: 100%;
  }

  .lang-button {
    justify-self: end;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
