import React, { useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Button from "./Button";
import Modal from "./CustomModal";

import { ResourcesContext } from "../contexts/ResourcesContext";

import { ReactComponent as CloseIcon } from "../assets/icons/icon_close.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/icon_filter.svg";
import { ReactComponent as CheckboxIcon } from "../assets/icons/icon_checkbox.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../assets/icons/icon_checkbox_selected.svg";

class MultiSelect extends React.Component {
  static contextType = ResourcesContext;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      // showModal: false,
      selectedList: this.props.selectedCategories.length > 0 ? this.props.selectedCategories : this.getSelectedList()
    };
  }
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isOpen: false
      });
    }
  };

  getSelectedList = () => {
    return this.props.options ? this.getOptionKeys() : [];
  }
  getOptionKeys = () => {
    const _array = [];
    this.props.options.map(item => {
      _array.push(item.key);
    });

    return _array;
  };

  handleCheck = key => {
    let _selectedList = this.state.selectedList;
    if (_selectedList.includes(key)) {
      _selectedList = _selectedList.filter(selectedKey => {
        return key != selectedKey;
      });
    } else {
      _selectedList.push(key);
    }

    this.setState({ selectedList: _selectedList });
  };

  getMobileContent = () => {
    const {
      label,
      className,
      showModal,
      options,
      handleSubmit,
      handleModal
    } = this.props;

    if (this.context.content === undefined) {
      return null;
    }

    const _content = this.context.content.modals;

    return (
      <Modal
        title={_content ? _content.service_filter_heading : "Service Categories"}
        closeModal={() => handleModal(false)}
        top="5%"
        show={true}
        footerLinkText={_content ? _content.modal_clear_text : "Clear"}
        footerLinkClick={() => this.setState({ selectedList: [] })}
        footerButtonText={_content ? _content.modal_save_text : "Save"}
        footerButtonClick={() => { handleSubmit(this.state.selectedList); }}
      >
        <ModalBody>
          <div className={"options-container" + (showModal ? " mobile" : "")}>
            <ul className="options">
              {options.map(option => {
                return (
                  <li
                    className="option"
                    key={option.key}
                    onClick={() => this.handleCheck(option.key)}
                  >
                    {this.state.selectedList.includes(option.key) ? (
                      <CheckboxSelectedIcon className="checkbox" />
                    ) : (
                        <CheckboxIcon className="checkbox" />
                      )}
                    <a href="#" className="T6">
                      {option.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  listenToFocusOut = () => {
    console.log("hello from focus out");
  };

  render() {
    const {
      label,
      className,
      showModal,
      options,
      handleSubmit,
      handleModal
    } = this.props;

    if (this.context.content === undefined) {
      return null;
    }

    const _content = this.context.content.modals;
    return (
      <MultiSelectContainer className={className} showModal={showModal}>
        <button
          className="select-button-mobile"
          onClick={() => handleModal(true)}
        >
          {/* <a href="#" className="T6">
            {_content ? _content.service_filter_button_text : "Filter"}
          </a> */}
          <FilterIcon />
        </button>
        <button
          className={
            "select-button" + (this.state.isOpen ? " select-open" : "")
          }
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <a href="#" className="T6">
            {label}
          </a>
          <i className="arrow-icon"></i>
        </button>
        {showModal ? this.getMobileContent() : null}
        {this.state.isOpen ? (
          <div
            className={"options-container" + (showModal ? " mobile" : "")}
            tabIndex="0"
          >
            <ul className="options">
              {options.map(option => {
                return (
                  <li
                    className="option"
                    key={option.key}
                    onClick={() => this.handleCheck(option.key)}
                  >
                    {this.state.selectedList.includes(option.key) ? (
                      <CheckboxSelectedIcon className="checkbox" />
                    ) : (
                        <CheckboxIcon className="checkbox" />
                      )}
                    <a href="#" className="T6">
                      {option.label}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="footer">
              <Button
                type="link"
                text={_content ? _content.modal_clear_text : "Clear"}
                onClick={() => this.setState({ selectedList: [] })}
              />
              <Button
                text={_content ? _content.modal_save_text : "Save"}
                onClick={() =>
                  this.setState(
                    { isOpen: false },
                    handleSubmit(this.state.selectedList)
                  )
                }
              />
            </div>
          </div>
        ) : null}
      </MultiSelectContainer>
    );
  }
}

export default MultiSelect;

const Close = styled(CloseIcon)`
  cursor: pointer;
`;

const ModalBody = styled.div`
  
  .options-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;

    &.mobile {
      border: none;
    }

    .options {
      padding: 0;
      .option {
        display: flex;
        z-index: 2;
        padding: 8px 0;
        list-style: none;

        .checkbox {
          width: 25px;
          margin: 2px 24px 0 0;
          align-items: flex-start;
          justify-content: center;
          cursor: pointer;
        }
        .T6 {
          align-self: flex-start;
          flex: 1;
        }

        /* &.selected {
          background-color: var(--C2);
          a {
            color: var(--C8);
          }
        } */
      }
    }

    .footer {
      border-top: 0.5px solid #b8bdc8;
      padding: 24px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

const MultiSelectContainer = styled.div`
  background-color: var(--C8);
  position: relative;
  border-radius: 8px;
  border: 1px solid #c6cad4;
  height: 100%;
  z-index: 3;

  a {
    text-decoration: none;
    color: var(--C4);
  }

  button {
    width: 100%;
    height: 100%;
    margin: 0;
    background: var(--C8);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-button-mobile {
    /* Shows on Mobile */
    justify-content: center;
    a {
      margin-right: 8px;
    }
  }
  .select-button {
    display: none;
  }

  @media screen and (min-width: 768px) {
    /* Tablet */
    .options-container {
      margin-top: 4px;
    }
    .select-button-mobile {
      display: none;
    }
    .select-button {
      display: flex;
      &.select-open {
        border-bottom-left-radius: 0 !important;
        /* border-bottom-right-radius: 0 !important; */
        .arrow-icon {
          transition: all 0.3s ease;
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }

      .arrow-icon {
        border: solid var(--C2);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 10px;

        transition: all 0.3s ease;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    .options-container {
      .options {
        max-height: 300px;
        overflow-y: scroll;
      }
    }
  }

  .options-container {
    position: absolute;
    border: 1px solid #edeef2;
    border-radius: 0 0 8px 8px;
    z-index: 2;
    padding: 24px 24px 0px 24px;
    background: var(--C8);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    border-radius: 8px;

    /* transform: translateY(-10px); */
    transition: all 0.3s ease;

    &.mobile {
      /* top: 0;
      left: 0; */
      border: none;
    }

    .options {
      padding: 0;
      .option {
        display: flex;
        z-index: 2;
        padding: 8px 0;
        list-style: none;

        .checkbox {
          width: 25px;
          margin: 2px 24px 0 0;
          align-items: flex-start;
          justify-content: center;
          cursor: pointer;
        }
        .T6 {
          align-self: flex-start;
          flex: 1;
        }

        /* &.selected {
          background-color: var(--C2);
          a {
            color: var(--C8);
          }
        } */
      }
    }

    .footer {
      border-top: 0.5px solid #b8bdc8;
      padding: 24px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;
