import React from 'react';
import styled from "styled-components";

const SkeletonElement = ({ type }) => {
  const classes = `${type}`;

  return (
    <Skeleton className={classes}></Skeleton>
  )
}

export default SkeletonElement;

const Skeleton = styled.div`
  background: var(--C11);
  margin: 8px 0;
  border-radius: 4px;

  &.service-tag {
    width: 80%;
    height: 18px;
  }

  &.service-title {
    width: 50%;
    height: 24px;
    margin-bottom: 10px;
  }

  &.service-logo {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;
