import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ServiceContext } from "../contexts/ServiceContext";
import { ResourcesContext } from "../contexts/ResourcesContext";
import CategoryTag from "./CategoryTag";
import AccessibilityTag from "./AccessibilityTag";
import SkeletonServiceList from "../skeletons/SkeletonServiceList";
import firebase from "../firebase";

const ServiceList = ({ handleListScroll }) => {
  const getTopHeight = () => {
    const SearchService = document.getElementById("SearchServices");
    if (SearchService) {
      return SearchService.offsetHeight;
    }
  }

  const { lang } = useContext(ResourcesContext);
  const { services, filteredServices, selectService, selectedService } = useContext(
    ServiceContext
  );
  const [headerSize, setHeaderSize] = useState("large");
  const [topHeight, setTopHeight] = useState(getTopHeight());

  const _filteredServices = filteredServices === null ? services : filteredServices;

  useEffect(() => {
    let resizer = new ResizeObserver(handleResize);
    resizer.observe(document.getElementById("SearchServices"));

    return () => resizer.unobserve(document.getElementById("SearchServices"));
  })

  const handleResize = () => {
    const SearchService = document.getElementById("SearchServices");
    if (SearchService) {
      setTopHeight(SearchService.offsetHeight);
    }
  }

  if (_filteredServices.length === 0) {
    return (
      <ServiceListContainer
        id="ServiceList"
        className={
          "service-list" + (selectedService.hasOwnProperty("id") ? " hidden" : "")
        }
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
          <SkeletonServiceList key={n} />
        ))}
      </ServiceListContainer>
    );
  }

  const selectedId = selectedService.hasOwnProperty("id")
    ? selectedService.id
    : _filteredServices[0].id;

  const handleScroll = (e) => {
    const element = e.target;
    if (window.screen.width < 960) {
      if (element.scrollTop > 320 && headerSize === "large") {
        setHeaderSize("small", handleListScroll("small"));
      } else if (element.scrollTop < 320 && headerSize === "small") {
        setHeaderSize("large", handleListScroll("large"));
      }
    }
  };


  return (
    <ServiceListContainer
      headerSize={headerSize}
      topHeight={topHeight}
      id="ServiceList"
      className={
        "service-list" + (selectedService.hasOwnProperty("id") ? " hidden" : "")
      }
      onScroll={(e) => handleScroll(e)}
    >
      {_filteredServices.length > 0 &&
        _filteredServices.map((service) => (<div
          className={
            "service " + (service.id === selectedId ? "selected" : null)
          }
          key={service.id}
          onClick={() => {
            firebase.analytics().logEvent('service_selected', { service_name: service['english'].name })
            selectService(service.id);
          }}
        >
          <h5>{service[lang].name}</h5>
          <div className="tags">
            <CategoryTag category={service.common.category} />
            {service.common.cost && <div className="tag-group">
              <AccessibilityTag tagKey={service.common.cost} />
              {service.common.tags.map((item, index) => (
                <AccessibilityTag key={index} tagKey={item} />
              ))}
            </div>}
          </div>
        </div>
        ))
      }
    </ServiceListContainer>
  );
};

export default ServiceList;

const ServiceListContainer = styled.div`
  width: 100%;
  background-color: var(--C8);
  position: relative;
  top: ${(props) => `${props.topHeight + 64}px`};
  height: ${(props) => `calc(100vh - ${props.topHeight}px)`};
  overflow-y: scroll;
  overflow-x: hidden;

  border-left: 0.5px solid var(--C12);
  border-right: 0.5px solid var(--C12);

  .service {
    padding: 8px 20px;
    display: grid;
    // grid-template-rows: repeat(2, 1fr);
    align-items: center;
    min-height: 92px;
    border-bottom: 0.5px solid var(--C12);
    cursor: pointer;

    &:last-child {
      padding-bottom: 100px;
    }

    &.selected {
      background-color: #fcfcfd;
      border-left: 3px solid #c3e3cb;
    }

    h5 {
      color: var(--C5);
      padding: 8px 0 8px 0;
    }

    .tags {
      margin-bottom: 8px;
      .tag-group {
        margin-top: 8px;
        max-width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
        
        div {
          margin-right: 8px;
        }
      }
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
    top: 0px;
    height: calc(100vh - 210px);
    .service {
    :last-child {
      padding-bottom: 0px;
    }
    .tags {
      .tag-group {
        margin-top: 8px;
        max-width: 100%;
        overflow-x: hidden;
      }
    }
  }
  }
`;
