import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ResourcesContext } from "../contexts/ResourcesContext";

import Button from "../components/Button";
import SamanaWhiteLogo from "../assets/logos/samana_white_logo.png";
import SamanaBlueLogo from "../assets/logos/samana_blue_logo.png";
import Pixel2 from "../assets/images/pixel_2_support_list.png";
import SoulsOfSamanaImage from "../assets/images/souls_of_samana.svg";
import BannerImage from "../assets/images/banner_illustration.svg";
import DisclaimerModal from "../modals/DisclaimerModal";

const AboutUsPage = () => {
  const { content } = useContext(ResourcesContext);
  const [modal, toggleModal] = useState(false);
  const history = useHistory();

  if (content === undefined) {
    return null;
  }
  const _content = content.whoweare;

  return (
    <>
      {modal && <DisclaimerModal closeDisclaimerModal={() => toggleModal(false)} />}
      <Container>
        <section className="banner">
          <div className="grid">
            <img src={BannerImage} alt="image outline of sri lanka" />
            <h3
              dangerouslySetInnerHTML={{
                __html: _content
                  ? _content.banner_text
                  : "Samana is a community driven and community built organization that works to provide access to <span class='h2'>mental health services for all Sri Lankans</span>",
              }}
            ></h3>
          </div>
        </section>

        <section className="vision">
          <div className="grid">
            <p className="T2">
              {_content
                ? _content.vision_text
                : "Our vision is to build an equal Sri Lanka by normalizing the conversation on mental health for everyone, no matter their race, religion, ability, health, status, gender, and sexual orientation."}
            </p>
          </div>
        </section>

        <section className="values">
          <h4 className="header">{_content ? _content.core_values_header : "Our Core Values"}</h4>
          <div className="cards grid">
            <div className="card card-trust">
              <img src={SamanaBlueLogo} alt="card image of samana logo" />
              <p className="T2">
                {_content
                  ? _content.core_values_trust
                  : "Build trust at every opportunity"}
              </p>
            </div>
            <div className="card card-empathy">
              <img src={SamanaWhiteLogo} alt="card image of samana logo" />
              <p className="T2">
                {_content
                  ? _content.core_values_empathy
                  : "Listen actively and empathetically"}
              </p>
            </div>
            <div className="card card-diverse">
              <img src={SamanaBlueLogo} alt="card image of samana logo" />
              <p className="T2">
                {_content
                  ? _content.core_values_diverse
                  : "Seek diverse perspectives"}
              </p>
            </div>
            <div className="card card-community">
              <img src={SamanaWhiteLogo} alt="card image of samana logo" />
              <p className="T2">
                {_content ? _content.core_values_community : "Create community"}
              </p>
            </div>
          </div>
        </section>

        <section className="support-list">
          <div className="grid">
            <img src={Pixel2} alt="image of support list page" />
            <div className="text">
              <h1>
                {_content ? _content.support_list_header : "The Support List"}
              </h1>
              <p className="B1">
                {_content
                  ? _content.support_list_about
                  : "We noticed that the number of support services in Sri Lanka is limited and can be hard to find. As the first phase of Samana, we set out to create a support list that is easily accessible to everyone. Services on our list are gathered through the recommendation of medical professionals and social workers."}
              </p>
              <p className="B2 italic">
                {_content
                  ? _content.support_list_disclaimer
                  : "samana.lk is not responsible for any issues arising related to services on the list, as we are not directly associated with the services/service providers but merely are trying to provide a comprehensive list of services that the community will benefit from. However, if any issues arise, please let us know so that we can take steps accordingly."}
              </p>

              <a onClick={() => toggleModal(!modal)} className="disclaimer-link">{_content ? _content.full_disclaimer_link_text : "Read the full disclaimer"}</a>
              <i className="disclaimer-arrow"></i>

              <Button
                text={_content ? _content.view_list_button_text : "View the List"}
                onClick={() => {
                  history.push("/support-list");
                }}
              />
            </div>
          </div>
        </section>

        <section className="team">
          <h1>{_content ? _content.team_header : "Souls of Samana"}</h1>
          <div className="grid">
            <h3>
              {_content
                ? _content.team_desc
                : "We would not have been able to do this without the help of these beautiful souls."}
            </h3>
          </div>
          <img src={SoulsOfSamanaImage} alt="souls of samana image" />
          <div className="team-members grid">
            <div className="list-1">
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.community_content.title
                    : "Community & Content"}
                </h5>
                {_content ? (
                  _content.team.community_content.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <p className="B1">Sharan Velauthan</p>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.brand_identity.title
                    : "Brand Identity"}
                </h5>
                {_content ? (
                  _content.team.brand_identity.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <p className="B1">Giselle Boehm</p>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.website_design.title
                    : "Website Design"}
                </h5>
                {_content ? (
                  _content.team.website_design.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <p className="B1">Ravin Perera</p>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.website_development.title
                    : "Website Development"}
                </h5>
                {_content ? (
                  _content.team.website_development.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <p className="B1">Aritha Hettiaratchi</p>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.website_illustrations.title
                    : "Website Illustrations"}
                </h5>
                {_content ? (
                  _content.team.website_illustrations.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <p className="B1">Danushri Welikala</p>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.website_translations.title
                    : "Website Translations"}
                </h5>
                {_content ? (
                  _content.team.website_translations.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <>
                      <p className="B1">Lakshikaa Srithar (Tamil)</p>
                      <p className="B1">Shevangi Sadesh Kumar (Tamil)</p>
                      <p className="B1">Charuni Kurumbalapitiya (Sinhala)</p>
                      <p className="B1">Pethmi Thilara (Sinhala)</p>
                      <p className="B1">Tahani Lenagala (Sinhala)</p>
                    </>
                  )}
              </div>
            </div>
            <div className="list-2">
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.research_interviews.title
                    : "Research Interviews"}
                </h5>
                {_content ? (
                  _content.team.research_interviews.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <>
                      <p className="B1">Aritha Wickramasinghe</p>
                      <p className="B1">Jayathma Wickramanayake</p>
                      <p className="B1">Jerusha Crossette-Thambiah</p>
                      <p className="B1">Kshyahla Jayawardena</p>
                      <p className="B1">Nilushka Perera</p>
                      <p className="B1">Senel Wanniarachchi</p>
                      <p className="B1">Sharanya Sekaram</p>
                      <p className="B1">Sriyal Nilanka</p>
                      <p className="B1">Sumaiya Shuaibdeen</p>
                    </>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.research_lead.title
                    : "Research Lead"}
                </h5>
                {_content ? (
                  _content.team.research_lead.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <>
                      <p className="B1">Anne Mendis</p>
                    </>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.consultants.title
                    : "Mental Health & Psychosocial Consultants"}
                </h5>
                {_content ? (
                  _content.team.consultants.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <>
                      <p className="B1">Thiviya Kandiah</p>
                      <p className="B1">Jananee Chandramohan</p>
                      <p className="B1">Sachini Akuretiya</p>
                    </>
                  )}
              </div>
              <div className="section">
                <h5>
                  {_content
                    ? _content.team.strategy_consulting.title
                    : "Strategy Consulting"}
                </h5>
                {_content ? (
                  _content.team.strategy_consulting.members.map((member) => {
                    return <p className="B1">{member}</p>;
                  })
                ) : (
                    <p className="B1">Graeme Arnison</p>
                  )}
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default AboutUsPage;

const Container = styled.div`
  position: relative;
  top: 64px;
  z-index: 1;
  
  @media screen and (min-width: 768px) {
    /* Tablet & Larger*/
   top: 0px;
  }

  section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .grid {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1140px;
  }

  .banner {
    background-color: var(--C1);
    color: var(--C2);

    img {
      grid-column: 1/5;
      grid-row: 1;
      justify-self: center;
      width: 100%;
    }

    h3 {
      padding-top: 40px;
      grid-column: 1/5;
      grid-row: 2;
      text-align: center;
    }
  }

  .vision {
    p {
      grid-column: 1/5;
      color: var(--C4);
      text-align: center;
    }
  }

  .values {
    background-color: var(--C25);
    .header {
      justify-content: center;
      display: flex;
      color: var(--C4);
    }
    .cards {
      margin-top: 48px;
      /* grid-template-columns: repeat(12,minmax(43.5px, 76.5px)); */
      /* grid-template-rows: repeat(2, 240px); */
      grid-template-rows: repeat(2, minmax(240px, 1fr));
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      .card {
        height: 100%;
        max-width: 306px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
        padding: 10px;

        img {
          height: 36px;
          margin-top: 30px;
        }

        p {
          margin-top: 30px;
          /* padding: 0px 10px; */
          width: 100%;
        }
      }
      .card-trust {
        grid-column: 1/3;
        grid-row: 1;
        background-color: var(--C1);
        color: var(--C2);
      }
      .card-empathy {
        grid-column: 3/5;
        grid-row: 1;
        background-color: var(--C4);
        color: var(--C8);
      }
      .card-diverse {
        grid-column: 1/3;
        grid-row: 2;
        background-color: var(--C24);
        color: var(--C2);
      }
      .card-community {
        grid-column: 3/5;
        grid-row: 2;
        background-color: var(--C3);
        color: var(--C8);
      }
    }
  }

  .support-list {
    img {
      height: 340px;
      grid-column: 1/5;
      grid-row: 1;
      justify-self: center;
    }
    .text {
      grid-column: 1/5;
      grid-row: 2;
      color: var(--C2);

      h1 {
        margin-top: 48px;
        margin-bottom: 24px;
      }

      .italic {
        margin: 16px 0px;
        font-family: "NotoSans LightItalic", sans-serif;
      }

      .disclaimer-link {
        color: var(--C2);
        font-family: "NotoSans Regular", "NotoSansSinhala Regular", "NotoSansTamil Regular", Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 150%;
        text-decoration: underline;
        cursor: pointer;
      }
      .disclaimer-arrow {
        border: solid var(--C2);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 4px;

        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        cursor: pointer;
      }

      .button {
        margin-top: 24px;
        width: 240px;
      }
    }
  }

  .team {
    color: var(--C2);
    text-align: center;
    background-color: var(--C25);

    h3 {
      margin-top: 16px;
      grid-column: 1/-1;
      grid-row: 1;
    }

    img {
      margin-top: 8px;
      height: 125px;
      padding: 0px 20px;
      width: 100%;
    }

    .team-members {
      margin-top: 24px;
      text-align: left;

      .list-1 {
        grid-column: 1/5;
        grid-row: 1;
      }
      .list-2 {
        grid-column: 1/5;
        grid-row: 2;
      }

      .section {
        margin-bottom: 36px;

        h5 {
          margin-bottom: 12px;
        }
      }
    }
  }

  /* Tablets */
  @media screen and (min-width: 768px) {
    .grid {
      grid-template-columns: repeat(8, 1fr);
    }

    .banner {
      img {
        grid-column: 6/9;
        grid-row: 1;
      }

      h3 {
        grid-column: 1/6;
        grid-row: 1;
        align-self: center;
        text-align: left;
      }
    }

    .vision {
      p {
        grid-column: 1/9;
      }
    }

    .values {
      .cards {
        grid-template-rows: repeat(4,minmax(80px, 1fr));
        grid-row-gap: 0px;
        .card {
          height: 100%;
        }
        .card-trust {
          grid-column: 1/3;
          grid-row: 1/4;
        }
        .card-empathy {
          grid-column: 3/5;
          grid-row: 2/5;
        }
        .card-diverse {
          grid-column: 5/7;
          grid-row: 1/4;
        }
        .card-community {
          grid-column: 7/9;
          grid-row: 2/5;
        }
      }
    }

    .support-list {
      img {
        grid-column: 2/4;
        grid-row: 1;
        align-self: center;
      }
      .text {
        grid-column: 5/9;
        grid-row: 1;
      }
    }

    .team {
      img {
        margin-top: 48px;
        height: 160px;
        padding: 0px;
      }

      .team-members {
        .list-1 {
          grid-column: 1/5;
          grid-row: 1;
        }
        .list-2 {
          grid-column: 5/9;
          grid-row: 1;
        }
      }
    }
  }

  /* Laptop */
  @media screen and (min-width: 960px) {
    .grid {
      grid-template-columns: repeat(12, 1fr);
      padding-left: 72px;
      padding-right: 72px;
      max-width: 1360px;
    }

    section {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .banner {
      padding-bottom: 128px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 230px;
        background-image: url("wave_white.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      img {
        grid-column: 6/13;
        grid-row: 1;
      }

      h3 {
        grid-column: 1/6;
        grid-row: 1;
      }
    }

    .vision {
      display: flex;
      align-items: center;
      height: 500px;
      padding-bottom: 200px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 340px;
        background-image: url("wave_grey.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      p {
        grid-column: 3/11;
      }
    }

    .values {
      .cards {
        grid-template-columns: repeat(12,64px);
        grid-template-rows: repeat(4, 100px);
        grid-row-gap: 0px;
        margin-top: 90px;
        justify-content: center;
        .card {
          height: 100%;
        }
        .card-trust {
          grid-column: 1/4;
          grid-row: 1/4;
        }
        .card-empathy {
          grid-column: 4/7;
          grid-row: 2/5;
        }
        .card-diverse {
          grid-column: 7/10;
          grid-row: 1/4;
        }
        .card-community {
          grid-column: 10/13;
          grid-row: 2/5;
        }
      }
    }

    .support-list {
      img {
        height: 500px;
        grid-column: 3/6;
        grid-row: 1;
        align-self: center;
      }
      .text {
        grid-column: 7/12;
        grid-row: 1;
      }
    }

    .team {
      img {
        height: 200px;
      }
      h3 {
        grid-column: 3/11;
      }

      .team-members {
        margin-top: 56px;
        .list-1 {
          grid-column: 3/6;
          grid-row: 1;
        }
        .list-2 {
          grid-column: 7/11;
          grid-row: 1;
        }
      }
    }
  }

  /* Larger screens for banner curve */
  @media screen and (min-width: 1340px) {
    .banner {
      padding-bottom: 250px;

      &:before {
        height: 250px;
      }
    }
    .vision {
      padding-bottom: 250px;
      &:before {
        height: 250px;
      }
    }
  }
  @media screen and (min-width: 1460px) {
    .banner {
      padding-bottom: 300px;

      &:before {
        height: 280px;
      }
    }
    .vision {
      padding-bottom: 200px;
      &:before {
        height: 280px;
      }
    }
  }
  @media screen and (min-width: 1500px) {
    .banner {
      padding-bottom: 300px;

      &:before {
        height: 300px;
      }
    }
    .vision {
      padding-bottom: 250px;
      &:before {
        height: 300px;
      }
    }
  }
  @media screen and (min-width: 1760px) {
    .banner {
      padding-bottom: 250px;
      position: relative;

      &:before {
        content:none;
        position: relative;
      }
    }
    .vision {
      padding-bottom: 100px;
      &:before {
        content:none;
        position: relative;
      }
    }
  }
`;
