import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ResourcesContext } from "../contexts/ResourcesContext";
import { ServiceContext } from "../contexts/ServiceContext";

import FeedbackImage from "../assets/images/support_list_feedback.svg";
import FeedbackThankYouImage from "../assets/images/support_list_feedback_thankyou.svg";
import Modal from "../components/CustomModal";
import Spinner from "../components/Spinner";
import PrivacyModal from "../modals/PrivacyModal";

const FeedbackModals = ({ show, serviceId, closeModal }) => {
  const { lang, content } = useContext(ResourcesContext);
  const { services } = useContext(ServiceContext);

  const [modal, togglePrivacyModal] = useState(false);
  const [flow, setFlow] = useState("intro");
  const [feedbackService, selectService] = useState(null);
  const [date, setDate] = useState(new Date());
  const [experience, setExperience] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  if (content === undefined) {
    return null;
  }
  const _content = content.modals;

  const sendFeedback = () => {
    if (experience === "") {
      const error = _content ? _content.feedback_error_message : "Please describe your experience below";
      setErrorMessage(error);
    } else {
      setLoading(true);
      setErrorMessage("");

      const payload = {
        service: feedbackService,
        date,
        experience,
      };

      console.log(payload);

      axios
        .post("/feedback/add", payload)
        .then((res) => {
          console.log(`email sent`);
          setLoading(false);
          setErrorMessage("");
          setFlow("thankyou");
        })
        .catch((err) => {
          console.log("error", err);
          window.scrollTo(0, 0);
          setLoading(false);
          setErrorMessage(
            "Oops! Looks like there was a problem. Please try again or reach out to us at reachsamana@gmail.com"
          );
        });
    }
  };

  const modalContent = () => {
    if (flow === "intro") {
      return (
        <>
          <div className="feedback-modal feedback-intro-body">
            <img src={FeedbackImage} alt="feedback image" />
            <div className="feedback-content">
              <h2>
                {_content
                  ? _content.feedback_intro_heading
                  : "Help ensure the quality and trustworthiness of Samana"}
              </h2>
              <p className="B1">
                {_content
                  ? _content.feedback_intro_desc
                  : "The feedback you provide will be anonymous, and will help keep us up to date on the quality of services listed."}
              </p>
              <p className="B2">
                {_content
                  ? _content.feedback_intro_footnote
                  : "More details available on our"}
                <a
                  className="T9 privacy-link"
                  onClick={() => togglePrivacyModal(!modal)}
                >
                  {_content
                    ? _content.feedback_intro_privacy_link
                    : "Privacy Policy"}
                </a>
              </p>
            </div>
          </div>
        </>
      );
    } else if (flow === "question1") {
      return (
        <>
          <div className="feedback-modal feedback-question-body">
            <div className="feedback-content">
              <p className="T3">
                {_content ? _content.feedback_question_label : "Question"} 1/3
              </p>
              <ProgressBar>
                <div className="current-progress progress-1"></div>
              </ProgressBar>
              <p className="T3">
                {_content
                  ? _content.feedback_question1_question
                  : "What is the name of the service where this experience took place?"}
              </p>
              <select
                name="services"
                className="services-list"
                onChange={(e) => selectService(e.target.value)}
              >
                {services.map((service) => (
                  <option
                    key={service.id}
                    value={service[lang].name}
                    selected={service.id === serviceId}
                  >
                    {service[lang].name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      );
    } else if (flow === "question2") {
      return (
        <>
          <div className="feedback-modal feedback-question-body">
            <div className="feedback-content">
              <p className="T3">
                {_content ? _content.feedback_question_label : "Question"} 2/3
              </p>
              <ProgressBar>
                <div className="current-progress progress-2"></div>
              </ProgressBar>
              <p className="T3">
                {_content
                  ? _content.feedback_question2_question
                  : "To the best of your memory, describe when this experience took place?"}
              </p>
              <DatePicker selected={date} onChange={(date) => setDate(date)} />
            </div>
          </div>
        </>
      );
    } else if (flow === "question3") {
      return (
        <>
          {loading ? <Spinner /> :
          <div className="feedback-modal feedback-question-body">
            <div className="feedback-content">
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <p className="T3">
                {_content ? _content.feedback_question_label : "Question"} 3/3
              </p>
              <ProgressBar>
                <div className="current-progress progress-3"></div>
              </ProgressBar>
              <p className="T3">
                {_content
                  ? _content.feedback_question3_question
                  : "Describe your experience"}
              </p>
              <textarea
                className="feedback-message"
                rows="6"
                name="textValue"
                placeholder={
                  _content
                    ? _content.feedback_question3_placeholder
                    : "My experience with..."
                }
                onChange={(e) => setExperience(e.target.value)}
              />
            </div>
          </div>}
        </>
      );
    } else if (flow === "thankyou") {
      return (
        <>
          <div className="feedback-modal feedback-intro-body">
            <img
              src={FeedbackThankYouImage}
              alt="thank you for feedback image"
            />
            <div className="feedback-content">
              <h2>
                {_content ? _content.feedback_thankyou_heading : "Thank You!"}
              </h2>
              <p className="B1">
                {_content
                  ? _content.feedback_thankyou_desc
                  : "Your feedback keeps Samana safe, trustworthy, and accessible for everyone."}
              </p>
            </div>
          </div>
        </>
      );
    }
  };

  const getFooterLinkText = () => {
    if (flow === "thankyou") {
      return null;
    }
    return _content ? _content.feedback_button_back : "Back";
  }

  const getFooterLinkClick = () => {
    let func = null;
    switch (flow) {
      case "intro":
        func = closeModal();
        break;
      case "question1":
        func = setFlow("intro");
        break;
      case "question2":
        func = setFlow("question1");
        break;
      case "question3":
        func = setFlow("question2");
        break;
    }
    return func;
  }

  const getFooterButtonText = () => {
    let text = "Get Started";
    switch (flow) {
      case "intro":
        text = _content ? _content.feedback_button_getstarted : "Get Started";
        break;
      case "question1":
        text = _content ? _content.feedback_button_next : "Next";
        break;
      case "question2":
        text = _content ? _content.feedback_button_next : "Next";
        break;
      case "question3":
        text = _content ? _content.feedback_button_next : "Next";
        break;
      case "thankyou":
        text = _content ? _content.feedback_button_backtolist : "Back to the list";
        break;
    }
    return text;
  }

  const getFooterButtonClick = () => {
    let func = null;
    switch (flow) {
      case "intro":
        func = setFlow("question1");
        break;
      case "question1":
        func = setFlow("question2");
        break;
      case "question2":
        func = setFlow("question3");
        break;
      case "question3":
        func = sendFeedback();
        break;
      case "thankyou":
        func = closeModal();
        break;
    }
    return func;
  }

  return (
    <>
      {modal ? (
        <PrivacyModal closePrivacyModal={() => togglePrivacyModal(false)} />
      ) : (
        <Modal
          title={_content ? _content.feedback_title : "Feedback"}
          closeModal={closeModal}
          width="650px"
          show={show}
          footerLinkText={getFooterLinkText()}
          footerLinkClick={() => getFooterLinkClick()}
          footerButtonText={getFooterButtonText()}
          footerButtonClick={() => getFooterButtonClick()}
          loading={loading}
        >
          <ModalBody>{modalContent()}</ModalBody>
        </Modal>
      )}
    </>
  );
};

export default FeedbackModals;

const ErrorMessage = styled.p`
  font-size: 15px;
  font-family: "NotoSans", sans-serif;
  color: #E44C4C;
  text-align: center;
`;

const ModalBody = styled.div`
  max-width: 650px;
  /* date picker styles */
  .react-datepicker__navigation {
    top: -30px;
  }
  .react-datepicker__navigation--next {
    border-left-color: var(--C2);
  }
  .react-datepicker__navigation--previous {
    border-right-color: var(--C2);
  }

  .feedback-modal {
    min-height: 300px;
    margin: 0 24px;
  }

  .feedback-intro-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 24px;
    align-items: center;
    margin: 0;

    img {
      grid-column: 1/2;
    }
    .feedback-content {
      grid-column: 2/5;
      h2 {
        color: var(--C7);
      }
      .B1 {
        margin: 16px 0px 24px 0px;
        color: var(--C5);
      }
      .privacy-link {
        color: var(--C2);
        margin-left: 4px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .feedback-question-body {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .feedback-modal {
      margin: 36px 0px 0 0px;
    }
    .feedback-intro-body {
      justify-items: center;
      grid-column-gap: 20px;
      img {
        grid-column: 1/-1;
        grid-row: 1;
        margin-bottom: 36px;
      }
      .feedback-content {
        grid-column: 1/-1;
        grid-row: 2;
      }
    }
    .modal-footer {
      /* justify-content: space-around !important; */
      justify-content: end;
    }
  }
`;

const ProgressBar = styled.div`
  margin: 16px 0px;
  width: 100%;
  height: 4px;
  background-color: var(--C10);
  border-radius: 8px;

  .current-progress {
    background-color: var(--C7);
    width: 0%;
    height: 100%;
    border-radius: 8px;
  }

  .progress-1 {
    width: 33.3%;
  }
  .progress-2 {
    width: 66.6%;
  }
  .progress-3 {
    width: 100%;
  }
`;
